import ButtonSettings from './ComponentSettings/Button';
import ButtongroupSettings from './ComponentSettings/Buttongroup';
import CheckboxSettings from './ComponentSettings/Checkbox';
import DependentSettings from './ComponentSettings/Dependent';
import DependentendSettings from './ComponentSettings/Dependentend';
import FileuploaderSettings from './ComponentSettings/Fileuploader';
import HcaptchaSettings from './ComponentSettings/Hcaptcha';
import HeadingSettings from './ComponentSettings/Heading';
import HtmlSettings from './ComponentSettings/Html';
import InputSettings from './ComponentSettings/Input';
import ParagraphSettings from './ComponentSettings/Paragraph';
import RadioSettings from './ComponentSettings/Radio';
import RecaptchaSettings from './ComponentSettings/Recaptcha';
import SelectSettings from './ComponentSettings/Select';
import TextareaSettings from './ComponentSettings/Textarea';

export const availableComponentSettings: any = {
    ButtonSettings,
    ButtongroupSettings,
    CheckboxSettings,
    DependentSettings,
    DependentendSettings,
    FileuploaderSettings,
    HcaptchaSettings,
    HeadingSettings,
    HtmlSettings,
    InputSettings,
    ParagraphSettings,
    RadioSettings,
    RecaptchaSettings,
    SelectSettings,
    TextareaSettings
};
