import Components from '../Interfaces/Components';

export default class Dependent implements Components {
    public componentType: string = 'dependent';
    public index        : number;
    public name         : string; // name of the parent field
    public value        : string; // values that will show the dependent fields

    constructor(index, name = '', value = '') {
        this.index = index;
        this.name = name;
        this.value = value;
    }
}
