import Components from '../Interfaces/Components';

export default class Hcaptcha implements Components {
    public componentType: string = 'hcaptcha';
    public index: number;
    public sitekey: string;
    public secretkey: string;
    public center: string;
    public size: string;
    public theme: string;

    constructor(index, size = 'normal', theme = 'light', center = 'false') {
        this.index = index;
        this.sitekey = '';
        this.secretkey = '';
        this.center = center;
        this.size = size;
        this.theme = theme;
    }
}
