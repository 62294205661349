import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class MaterialTimepicker extends Plugins implements Pluginz {
    public objectName: string = 'MaterialTimepicker';
    public pluginName: string = 'material-timepicker';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'default-time',
            value: null
        },
        {
            name: 'from-now',
            value: null
        },
        {
            name: 'twelve-hour',
            value: 'true'
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#material-timepicker-example');
        const $legend = $('<legend />').html('Material Timepicker').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // data-default-time
        const defaultTimeData = {
            data: {
                plugin: 'MaterialTimepicker',
                prop: 'dataAttributes',
                attributename: 'default-time'
            },
            name: 'default-time',
            label: 'Default time',
            tooltip: 'Default time to set on the timepicker. ie: &apos;now&apos; or &apos;13: 14&apos;<br><br>Default: null',
            value: this.getAttributeValue('default-time')
        }
        const $inputDefaultTime = compSettings.addInput(defaultTimeData);

        $fieldset.append($inputDefaultTime);

        // data-from-now
        const fromNowData = {
            data: {
                plugin: 'MaterialTimepicker',
                prop: 'dataAttributes',
                attributename: 'from-now'
            },
            type: 'number',
            name: 'from-now',
            label: 'From now',
            tooltip: 'Millisecond offset from the defaultTime.',
            value: this.getAttributeValue('from-now')
        }
        const $inputFromNow = compSettings.addInput(fromNowData);

        $fieldset.append($inputFromNow);

        // twelve-hour
        const selectData = {
            data: {
                plugin: 'MaterialTimepicker',
                prop: 'dataAttributes',
                attributename: 'twelve-hour'
            },
            name: 'twelve-hour',
            label: 'Clock',
            tooltip: 'Use 12 hour AM/PM clock instead of 24 hour clock<br><br>Default: &apos;true&apos;',
            value: this.getAttributeValue('twelve-hour')
        }
        const selectOptions: any = [{ '12 hour AM/PM': 'true' }, { '24 hour': 'false' }];
        const $selectjsConfig = compSettings.addSelect(selectData, selectOptions);

        $fieldset.append($selectjsConfig);

        return $fieldset;
    }
}
