declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';
import { availablePlugins } from '../availablePlugins';

export default class Select extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // Name
        const nameData = {
            data: {
                prop: 'name'
            },
            label: 'Name',
            name: 'name',
            value: this.component.name,
            pattern: '[a-z0-9_\\[\\]-]+',
            tooltip: 'alphanumeric characters, hyphens, underscores and hooks only.'
        }
        const $inputName = this.addInput(nameData);
        $mainTab.push($inputName);

        // Value
        const $selectValue = this.buildSelectDefaultValue(this.component.value, this.component.selectOptions);
        $mainTab.push($selectValue);

        // Label
        const labelData = {
            data: {
                prop: 'label'
            },
            label: 'Label',
            name: 'label',
            value: this.component.label
        }
        const $inputLabel = this.addInput(labelData);
        $mainTab.push($inputLabel);

        // Placeholder
        const placeholderData = {
            data: {
                prop: 'placeholder'
            },
            label: 'Placeholder',
            name: 'placeholder',
            value: this.component.placeholder
        }
        const $inputPlaceholder = this.addInput(placeholderData);
        $mainTab.push($inputPlaceholder);

        // HelpText
        const helperData = {
            data: {
                prop: 'helper'
            },
            label: 'Helper',
            name: 'helper',
            tooltip: 'Helper text displayed below the field.',
            value: this.component.helper
        }
        const $inputHelper = this.addInput(helperData);
        $mainTab.push($inputHelper);

        // Class
        const clazzData = {
            data: {
                prop: 'clazz'
            },
            label: 'Class',
            name: 'clazz',
            value: this.component.clazz
        }
        const $inputClazz = this.addInput(clazzData);
        $mainTab.push($inputClazz);

        // width
        const isFormHorizontal = $('#fg-form-wrapper form').hasClass('form-horizontal');
        const selectWidthData = {
            data: {
                prop: 'width'
            },
            disabled: !isFormHorizontal,
            name: 'input-width-data',
            tooltip: 'The width can be set only in horizontal forms. It has no effect on others layouts.',
            label: 'Width',
            value: '100%'
        }
        const selectWidthOptions: Array<string> = ['33%', '50%', '66%', '100%'];
        const $selectWidth = this.addSelect(selectWidthData, selectWidthOptions);

        $mainTab.push($selectWidth);

        // Attr
        const checkData = {
            checkboxes: [{
                checked: this.component.attr.find(a => a.name === 'disabled') !== undefined,
                data: {
                    prop: 'disabled'
                },
                label: 'Disabled',
                name: 'disabled',
                value: 'disabled'
            }, {
                checked: this.component.attr.find(a => a.name === 'required') !== undefined,
                data: {
                    prop: 'required'
                },
                label: 'Required',
                name: 'required',
                value: 'required'
            }, {
                checked: this.component.attr.find(a => a.name === 'multiple') !== undefined,
                data: {
                    prop: 'multiple'
                },
                label: 'Multiple',
                name: 'multiple',
                value: true
            }],
            label: '',
            plugin: 'niceCheck'
        }
        const $checkMisc = this.addCheckboxes(checkData);
        $mainTab.push($checkMisc);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        /* selectOptions
        -------------------------------------------------- */

        const $selectOptionsTab: Array<JQuery> = [];

        const $toggleCollapse = $(`<div class="d-flex justify-content-between my-1">
            <button type="button" class="${this.ui.topButtonsClass} collapse-all-btn" role="button"><small>Collapse <i class="icon-shrink text-white ms-1"></i></small></button>
            <button type="button" class="${this.ui.topButtonsClass} expand-all-btn" role="button"><small>Expand <i class="icon-expand text-white ms-1"></i></small></button></div>`);

        $selectOptionsTab.push($toggleCollapse);

        const $itemsWrapper = $('<div />').attr('id', 'items-wrapper');

        this.component.selectOptions.forEach(opt => {
            const $fieldset = this.buildSelectOption(opt);
            $itemsWrapper.append($fieldset);
        });

        $selectOptionsTab.push($itemsWrapper);

        const $addOptionBtn = $(`<div class="d-flex justify-content-end my-1"><button type="button" class="${this.ui.addButtonsClass} add-btn" role="button"><small>Add new <i class="icon-plus-circle text-white ms-1"></i></small></button></div>`);

        $selectOptionsTab.push($addOptionBtn);

        this.tabs.push({
            title: 'Options',
            id: 'tab-items',
            content: $selectOptionsTab
        });

        /* Plugins
        -------------------------------------------------- */

        const $pluginsTab: Array<JQuery> = [];

        // Plugins
        const pluginsOptions: Array<string> = [];
        availablePlugins.select.forEach(Pl => {
            pluginsOptions.push(Pl.name);
        });
        const activePluginsNames: Array<string> = [];
        this.component.plugins.forEach(pl => {
            activePluginsNames.push(pl.name);
        });
        const pluginsData = {
            data: {
                prop: 'plugins'
            },
            label: 'Select plugins',
            name: 'plugins[]',
            value: activePluginsNames
        }
        const $selectPlugins = this.addSelect(pluginsData, pluginsOptions);
        $pluginsTab.push($selectPlugins);

        this.component.plugins.forEach(pl => {
            const $pluginFieldset = this.loadPlugin(pl, this);
            $pluginsTab.push($pluginFieldset);
        });

        this.tabs.push({
            title: 'Plugins',
            id: 'tab-plugins',
            content: $pluginsTab
        });

        this.renderSection(this.tabs, () => {
            this.component.selectOptions.forEach(opt => {
                // set delete btn popover
                const popoverDeleteData = {
                    sectionId: this.section.id,
                    componentType: 'select',
                    itemIndex: opt.index
                };
                this.enableDeletePopover(popoverDeleteData, `#tab-items fieldset[data-index="${opt.index}"]`);
            });
        });
    }

    private buildSelectOption(opt) {
        const $fieldset = $('<fieldset />').addClass(this.ui.fieldsetClass).attr('data-index', opt.index);
        const legendHtml = `<button class="${this.ui.collapsibleButtonsClass}" type="button" data-bs-toggle="collapse" data-bs-target="#collapsible-option-${opt.index}" aria-expanded="true" aria-controls="collapsible-option-${opt.index}">option ${opt.index}</button>`;
        const $legend = $('<legend />').html(legendHtml).addClass(this.ui.legendClass);
        $fieldset.append($legend);

        const $btnDelete = `<button type="button" class="btn btn-link text-decoration-none btn-delete" data-bs-toggle="popover"><i class="icon-delete text-danger"></i></button>`;
        $fieldset.append($btnDelete);

        const $collapsible = $('<div />').addClass('collapse show').prop('id', `collapsible-option-${opt.index}`);

        // Text
        const textData = {
            data: {
                index: opt.index,
                prop: 'text'
            },
            label: 'Text',
            name: 'option-' + opt.index,
            value: opt.text
        }

        const $inputText = this.addInput(textData);
        $collapsible.append($inputText);

        // Value
        const valueData = {
            data: {
                index: opt.index,
                prop: 'value'
            },
            label: 'Value',
            name: 'option-value' + opt.index,
            value: opt.value
        }
        const $inputValue = this.addInput(valueData);
        $collapsible.append($inputValue);

        // Groupname
        const groupnameData = {
            data: {
                index: opt.index,
                prop: 'groupname'
            },
            label: 'Group name',
            name: 'option-groupname' + opt.index,
            value: opt.groupname
        }
        const $inputGroupname = this.addInput(groupnameData);
        $collapsible.append($inputGroupname);

        $fieldset.append($collapsible);

        return $fieldset;
    }
}
