declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';

export default class Paragraph extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // Value
        const valueData = {
            data: {
                prop: 'value'
            },
            helper: 'HTML authorized',
            label: 'Text',
            name: 'value',
            value: this.component.value
        }
        const $inputValue = this.addTextarea(valueData);
        $mainTab.push($inputValue);

        // Class
        const clazzData = {
            data: {
                prop: 'clazz'
            },
            label: 'Class',
            name: 'clazz',
            value: this.component.clazz
        }
        const $inputClazz = this.addInput(clazzData);
        $mainTab.push($inputClazz);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        this.renderSection(this.tabs);
    }
}
