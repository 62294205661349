import Components from '../Interfaces/Components';
import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';

import ComponentsMain from './ComponentsMain';

export default class Input extends ComponentsMain implements Components {
    public attr         : Array<Attribute>;
    public clazz        : string;
    public componentType: string = 'input';
    public helper       : string = '';
    public icon         : string = '';
    public iconPosition : string = 'before';
    public index        : number;
    public label        : string;
    public name         : string;
    public placeholder  : string;
    public plugins      : Array<Pluginz> = [];
    public type         : string;
    public value        : string;
    public width        : string;

    constructor(index, type = 'text', name = 'input', value = '', label = 'Input', attr = [], clazz = '', placeholder = '', width = '100%') {
        super();
        this.attr        = attr;
        this.clazz       = clazz;
        this.index       = index;
        this.label       = `${label} ${index}`;
        this.name        = `${name}-${index}`;
        this.placeholder = placeholder;
        this.type        = type;
        this.value       = value;
        this.width       = width;

        // merge clazz & placeholder with attributes for PHP Form Builder
        this.attr = this.mergeAttributes(this);
    }
}
