import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Tooltip extends Plugins implements Pluginz {
    public objectName: string = 'Tooltip';
    public pluginName: string = 'tooltip';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'tooltip',
            value: ''
        },
        {
            name: 'animation',
            value: 'fade'
        },
        {
            name: 'arrow',
            value: 'true'
        },
        {
            name: 'delay',
            value: '0'
        },
        {
            name: 'theme',
            value: 'light'
        },
        {
            name: 'trigger',
            value: 'mouseenter'
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#tooltip-example');
        const $legend = $('<legend />').html('Tooltip').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // tooltip
        const tooltipData = {
            data: {
                plugin: 'Tooltip',
                prop: 'dataAttributes',
                attributename: 'tooltip'
            },
            name: 'tooltip',
            label: 'Text content',
            tooltip: 'The tooltip text content.<br>If you want to add some HTML code this can only be done in the form PHP code, please refer to the documentation',
            value: this.getAttributeValue('tooltip')
        }
        const $tooltipInput = compSettings.addInput(tooltipData);

        $fieldset.append($tooltipInput);

        // animation
        const selectAnimationData = {
            data: {
                plugin: 'Tooltip',
                prop: 'dataAttributes',
                attributename: 'animation'
            },
            name: 'animation-data',
            label: 'Animation',
            value: this.getAttributeValue('animation')
        }
        const selectOptions: Array<string> = ['fade', 'shift-away', 'shift-toward', 'scale', 'perspective'];
        const $selectAnimation = compSettings.addSelect(selectAnimationData, selectOptions);

        $fieldset.append($selectAnimation);

        // arrow
        const arrowData = {
            checkboxes: [{
                checked: this.getAttributeChecked('arrow'),
                data: {
                    plugin: 'Tooltip',
                    prop: 'dataAttributes',
                    attributename: 'arrow'
                },
                name: 'arrow',
                label: '',
                value: true
            }],
            label: 'Arrow',
            tooltip: 'The arrow that points toward the element.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $arrowCheck = compSettings.addCheckboxes(arrowData);
        $fieldset.append($arrowCheck);

        // delay
        const delayData = {
            data: {
                plugin: 'Tooltip',
                prop: 'dataAttributes',
                attributename: 'delay'
            },
            name: 'delay',
            label: 'Delay',
            tooltip: 'The delay in milliseconds before opening the tooltip.',
            value: this.getAttributeValue('delay')
        }
        const $delayInput = compSettings.addInput(delayData);

        $fieldset.append($delayInput);

        // theme
        const selectThemeData = {
            data: {
                plugin: 'Tooltip',
                prop: 'dataAttributes',
                attributename: 'theme'
            },
            name: 'theme-data',
            label: 'Theme',
            value: this.getAttributeValue('theme')
        }
        const selectThemeOptions: Array<string> = ['light', 'light-border', 'material', 'translucent', 'null'];
        const $selectTheme = compSettings.addSelect(selectThemeData, selectThemeOptions);

        $fieldset.append($selectTheme);

        // trigger
        const triggerData = {
            data: {
                plugin: 'Tooltip',
                prop: 'dataAttributes',
                attributename: 'trigger'
            },
            name: 'trigger',
            label: 'Trigger',
            helper: 'Determines the events that cause the tippy to show. Multiple event names are separated by spaces.<br>For instance: \'mouseenter focus\'.',
            value: this.getAttributeValue('trigger')
        }
        const $triggerInput = compSettings.addInput(triggerData);

        $fieldset.append($triggerInput);

        return $fieldset;
    }
}
