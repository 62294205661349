import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class MaterialDatepicker extends Plugins implements Pluginz {
    public objectName: string = 'MaterialDatepicker';
    public pluginName: string = 'material-datepicker';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'default-date',
            value: null
        },
        {
            name: 'format',
            value: 'mmm dd, yyyy'
        },
        {
            name: 'min-date',
            value: null
        },
        {
            name: 'max-date',
            value: null
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#material-datepicker-example');
        const $legend = $('<legend />').html('Material Datepicker').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // data-format
        const formatData = {
            data: {
                plugin: 'MaterialDatepicker',
                prop: 'dataAttributes',
                attributename: 'format'
            },
            name: 'format',
            label: 'Format',
            tooltip: 'The date output format for the input field value.<br><br>Default: &apos;mmm dd, yyyy&apos;',
            value: this.getAttributeValue('format')
        }
        const $inputFormat = compSettings.addInput(formatData);

        $fieldset.append($inputFormat);

        // data-default-date
        const defaultDateData = {
            data: {
                plugin: 'MaterialDatepicker',
                prop: 'dataAttributes',
                attributename: 'default-date'
            },
            name: 'default-date',
            label: 'Default date',
            tooltip: 'The initial date to view when first opened.<br><br>The date must be a string in Javascript Date Object format. ie: 2020-01-24<br><br>Default: null',
            helper: '<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date" target="_blank">View available formats <i class="icon-outlink ms-1"></i></a>',
            value: this.getAttributeValue('default-date')
        }
        const $inputDefaultDate = compSettings.addInput(defaultDateData);

        $fieldset.append($inputDefaultDate);

        // data-min-date
        const minDateData = {
            data: {
                plugin: 'MaterialDatepicker',
                prop: 'dataAttributes',
                attributename: 'min-date'
            },
            name: 'min-date',
            label: 'Min. date',
            tooltip: 'The earliest date that can be selected..<br><br>The date must be a string in Javascript Date Object format. ie: 2020-01-24<br><br>Default: null',
            helper: '<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date" target="_blank">View available formats <i class="icon-outlink ms-1"></i></a>',
            value: this.getAttributeValue('min-date')
        }
        const $inputMinDate = compSettings.addInput(minDateData);

        $fieldset.append($inputMinDate);

        // data-max-date
        const maxDateData = {
            data: {
                plugin: 'MaterialDatepicker',
                prop: 'dataAttributes',
                attributename: 'max-date'
            },
            name: 'max-date',
            label: 'Max. date',
            tooltip: 'The latest date that can be selected..<br><br>The date must be a string in Javascript Date Object format. ie: 2020-01-24<br><br>Default: null',
            helper: '<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date" target="_blank">View available formats <i class="icon-outlink ms-1"></i></a>',
            value: this.getAttributeValue('max-date')
        }
        const $inputMaxDate = compSettings.addInput(maxDateData);

        $fieldset.append($inputMaxDate);

        return $fieldset;
    }
}
