declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';

export default class Recaptcha extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        const $heading = $('<p />').addClass('alert alert-info mt-4').html('Get your private and public keys on Google Recaptcha website.<br><hr><br>The version to use is Recaptcha V3.<br><hr><br>If you don\'t enter a valid key the Recaptcha will not show.');
        $mainTab.push($heading);

        // privatekey
        const privatekeyData = {
            data: {
                prop: 'privatekey'
            },
            label: 'Private key',
            name: 'privatekey',
            value: this.component.privatekey
        }
        const $inputPrivatekey = this.addInput(privatekeyData);
        $mainTab.push($inputPrivatekey);

        // privatekey
        const publickeyData = {
            data: {
                prop: 'publickey'
            },
            label: 'Public key',
            name: 'publickey',
            value: this.component.publickey
        }
        const $inputPublickey = this.addInput(publickeyData);
        $mainTab.push($inputPublickey);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        this.renderSection(this.tabs);
    }
}
