import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Colorpicker extends Plugins implements Pluginz {
    public objectName: string = 'Colorpicker';
    public pluginName: string = 'colorpicker';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'theme',
            value: 'classic'
        },
        {
            name: 'lock-opacity',
            value: 'false'
        },
        {
            name: 'preview',
            value: 'true'
        },
        {
            name: 'hue',
            value: 'true'
        },
        {
            name: 'output-format',
            value: 'HEX'
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        let checked;
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#colorpicker-example');
        const $legend = $('<legend />').html('Colorpicker').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // Theme
        const selectThemeData = {
            data: {
                plugin: 'Colorpicker',
                prop: 'dataAttributes',
                attributename: 'theme'
            },
            name: 'colorpicker-theme-data',
            label: 'Theme',
            value: this.getAttributeValue('theme')
        }
        const selectOptions: Array<string> = ['classic', 'monolith', 'nano'];
        const $selectTheme = compSettings.addSelect(selectThemeData, selectOptions);

        $fieldset.append($selectTheme);

        // Lock opacity
        checked = this.getAttributeChecked('lock-opacity');
        const lockOpacityData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Colorpicker',
                    prop: 'dataAttributes',
                    attributename: 'lock-opacity'
                },
                name: 'lock-opacity',
                label: '',
                value: true
            }],
            label: 'Lock opacity',
            tooltip: 'If Yes, the user won\'t be able to adjust any opacity.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $lockOpacityCheck = compSettings.addCheckboxes(lockOpacityData);
        $fieldset.append($lockOpacityCheck);

        // Preview
        checked = this.getAttributeChecked('preview');
        const previewData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Colorpicker',
                    prop: 'dataAttributes',
                    attributename: 'preview'
                },
                name: 'preview',
                label: '',
                value: true
            }],
            label: 'Preview',
            tooltip: 'Enable / disable the preview.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $previewCheck = compSettings.addCheckboxes(previewData);
        $fieldset.append($previewCheck);

        // Hue
        checked = this.getAttributeChecked('hue');
        const hueData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Colorpicker',
                    prop: 'dataAttributes',
                    attributename: 'hue'
                },
                name: 'hue',
                label: '',
                value: true
            }],
            label: 'Hue',
            tooltip: 'Enable / disable the hue adjustment.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $hueCheck = compSettings.addCheckboxes(hueData);
        $fieldset.append($hueCheck);

        // Output format
        const selectOutputFormatData = {
            data: {
                plugin: 'Colorpicker',
                prop: 'dataAttributes',
                attributename: 'output-format'
            },
            name: 'colorpicker-output-format-data',
            label: 'Output format',
            value: this.getAttributeValue('output-format')
        }
        const OfOptions: Array<string> = ['HEX', 'RGBA', 'HSVA', 'HSLA', 'CMYK'];
        const $selectOutputFormat = compSettings.addSelect(selectOutputFormatData, OfOptions);

        $fieldset.append($selectOutputFormat);

        return $fieldset;
    }
}
