import Components from '../Interfaces/Components';

export default class Fileuploader implements Components {
    public componentType: string = 'fileuploader';
    public label: string;
    public name: string;
    public helper: string;
    public width: string;
    public xml: string;
    public uploadDir: string;
    public limit: string;
    public extensions: string;
    public fileMaxSize: string;
    public thumbnails: string;
    public index: number;
    public editor: string;
    public widthImg: string;
    public heightImg: string;
    public crop: string;

    constructor(index, label = 'Upload', name = 'uploader', helper = '', width = '100%', xml = 'default', uploadDir = '/file-uploads/', limit = '1', extensions = '', fileMaxSize = '5', thumbnails = 'false', editor = 'false', widthImg = '', heightImg = '', crop = 'false') {
        this.index = index;
        this.label = `${label} ${index}`;
        this.name = `${name}-${index}`;
        this.helper = helper;
        this.width = width;
        this.xml = xml;
        this.uploadDir = uploadDir;
        this.limit = limit;
        this.extensions = extensions;
        this.fileMaxSize = fileMaxSize;
        this.thumbnails = thumbnails;
        this.editor = editor;
        this.widthImg = widthImg;
        this.heightImg = heightImg;
        this.crop = crop;
    }
}
