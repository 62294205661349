import { defaultConfig } from '../defaultConfig';
import Pluginz from '../Interfaces/Pluginz';

export default class Form {
    public ajax: string = defaultConfig.userForm.ajax;
    public attr: string = defaultConfig.userForm.attr;
    public clazz: string;
    public framework: string = defaultConfig.userForm.framework; // bs4 | bs4-material | bs5 | bulma | foundation | material | tailwind | uikit
    public iconFont: string = defaultConfig.userForm.iconFont;
    public iconFontUrl: string = defaultConfig.userForm.iconFontUrl;
    public id: string = defaultConfig.userForm.id;
    public layout: string = defaultConfig.userForm.layout;
    public plugins: Array<Pluginz> = defaultConfig.userForm.plugins;

    public aftervalidation: string = defaultConfig.userForm.aftervalidation;  // 'send-email' | 'db-insert' | 'db-update' | 'db-delete'
    public redirectUrl: string = defaultConfig.userForm.redirectUrl;

    // email
    public senderEmail: string = defaultConfig.userForm.senderEmail;
    public recipientEmail: string = defaultConfig.userForm.recipientEmail;
    public subject: string = defaultConfig.userForm.subject;
    public senderName: string = defaultConfig.userForm.senderName;
    public replyToEmail: string = defaultConfig.userForm.replyToEmail;
    public sentMessage: string = defaultConfig.userForm.sentMessage;

    // database
    public dbTable: string = defaultConfig.userForm.dbTable;
    public dbPrimary: string = defaultConfig.userForm.dbPrimary;
    public dbFilter: string = defaultConfig.userForm.dbFilter;

    constructor() {
    }
}
