declare const $: any;
declare const formGenerator: any;
declare const lc_switch: any;
declare const NiceCheck: any;
declare const SlimSelect: any;
declare const UniversalIconPicker: any;

import { componentSettingsDefaultConfig } from './ComponentSettingsDefaultConfig';
import PopoverDeleteData from '../Interfaces/PopoverDeleteData';
import Section from '../Interfaces/Section';
import Tabs from '../Interfaces/Tabs';

export default class ComponentSettings {
    protected ui;
    protected section: Section;

    constructor(section) {
        this.ui = componentSettingsDefaultConfig.ui;
        this.section = section;
    }

    public loadPlugin(pl, compSettings) {

        return pl.render(compSettings);
    }

    public refreshComponentSettingsFormPlugins($pluginFieldset = null) {
        let $target = $pluginFieldset;
        if ($pluginFieldset === null) {
            $target = $('#components-settings-form');
        }
        if ($target.attr('id') === undefined) {
            $target.attr('id', this.makeUniqId(5));
        }
        let _nc = new NiceCheck('#' + $target.attr('id'));
        $target.find('[data-slimselect="true"]').each((index, el) => {
            let elId = $(el).attr('id');
            if (elId === undefined) {
                elId = this.makeUniqId(4);
                $(el).attr('id', elId);
            }
            let _slm = new SlimSelect({ select: '#' + elId, showSearch: false });
        });
        $target.find('[data-bs-toggle="tooltip"]').tooltip();
        // Autosize textareas
        $target.find('textarea').trigger('keyup');
        $target.find('[data-bs-toggle="tooltip"]').tooltip();
        $target.find('.lcswitch').each(function (index, el) {
            lc_switch(el, {
                on_txt: el.dataset.ontext === undefined ? 'On' : el.dataset.ontext,
                off_txt: el.dataset.offtext === undefined ? 'Off' : el.dataset.offtext,
                on_color: el.dataset.oncolor === undefined ? null : el.dataset.oncolor,
                compact_mode: false
            });
        });

        // Universal Icon Picker
        if ($('#' + $target.attr('id') + ' button[role="iconpicker"]')[0]) {
            $('#' + $target.attr('id') + ' button[role="iconpicker"]').each((index, $btn) => {
                const btnIconSelector = '#' + $btn.id;
                const options = {
                    iconLibraries: [
                        $('body').attr('data-icon-font') + '.min.json'
                    ],
                    iconLibrariesCss: [
                        $('body').attr('data-icon-font-url')
                    ],
                    // mode: 'onrequest',
                    // resetSelector: '#uip-reset-btn',
                    onBeforeOpen: function () {
                        let currentIconLibrary = $('body').attr('data-icon-font'),
                            currentIconLibraryCss = $('body').attr('data-icon-font-url');
                        uip.setOptions({
                            iconLibraries: [currentIconLibrary + '.min.json'],
                            iconLibrariesCss: [currentIconLibraryCss]
                        });
                    },
                    onSelect: function (jsonIconData) {
                        if (jsonIconData.iconClass !== null) {
                            if (jsonIconData.iconClass.match(/material/)) {
                                $(btnIconSelector).data('icon', [jsonIconData.iconClass, jsonIconData.iconText].join(' '));
                            } else {
                                $(btnIconSelector).data('icon', jsonIconData.iconClass);
                            }
                            $(btnIconSelector + ' #icon-output').html(jsonIconData.iconHtml);
                        } else {
                            $(btnIconSelector + ' #icon-output').text('Open the icon picker');
                            $(btnIconSelector).data('icon', null);
                        }
                        $(btnIconSelector).trigger('change');
                    },
                    onReset: function () {
                        // document.getElementById('output-icon').innerHTML = '';
                    }
                }

                // Instanciate the Icon Picker
                var uip = new UniversalIconPicker(btnIconSelector, options);
            });
        }
    }

    protected addDocumentation($link) {

        return $('<a />').prop('href', $link).attr('target', '_blank').addClass('badge badge-primary ms-2').html('doc <i class="ms-1 icon-outlink text-light"></i>');
    }

    protected addCheckboxes(checkData) {
        let $label: JQuery;
        let $formGroup: JQuery;

        if (checkData.plugin !== undefined && checkData.plugin == 'lcswitch') {
            $formGroup = $('<div />').attr('class', this.ui.lcswitchGroupClass);
        } else {
            $formGroup = $('<div />').attr('class', this.ui.niceCheckGroupClass);
        }

        if (checkData.label.length > 0) {
            $label = $('<label />').attr({
                class: this.ui.labelClass,
                for: checkData.name
            }).html(checkData.label);

            if (checkData.tooltip !== undefined && checkData.tooltip.length > 0) {
                const $tooltip = this.tooltip(checkData.tooltip);
                $label.append($tooltip);
            }

            $formGroup.append($label);
        }
        checkData.checkboxes.forEach(chkb => {
            let $checkboxGroup: JQuery = $('<div />').attr('class', this.ui.formCheckClass);
            $label = $('<label />').attr({
                class: this.ui.labelClass,
                for: chkb.name
            }).addClass('form-check-label').html(chkb.label);

            if (chkb.label.length === 0) {
                $label.addClass('d-none');
            }

            if (checkData.plugin !== undefined && checkData.plugin == 'niceCheck') {
                $label.addClass('nice-check');
            }

            let $checkbox: JQuery = $('<input />').attr({
                class: 'form-check-input',
                type: 'checkbox',
                id: chkb.name,
                value: chkb.value
            });
            Object.keys(chkb.data).forEach(key => {
                let value = chkb.data[key];
                $checkbox.attr('data-' + key, value);
            });

            if (chkb.checked === true) {
                $checkbox.attr({
                    checked: 'checked'
                });
            }

            if (checkData.plugin !== undefined && checkData.plugin == 'lcswitch') {
                $checkbox.addClass('lcswitch').attr({
                    'data-ontext': checkData.dataontext,
                    'data-offtext': checkData.dataofftext,
                    'data-theme': 'blue'
                })
            }

            $checkboxGroup.append($checkbox).append($label);
            $formGroup.append($checkboxGroup);
        });

        if (checkData.helper !== undefined && checkData.helper.length > 0) {
            const $helper = this.helper(checkData.helper);
            $formGroup.append($helper);
        }

        return $formGroup;
    }

    protected addIconPicker(iconData: { data: any; label: any; name: any; tooltip?: any; helper?: any; }) {
        let $label: JQuery;
        let $button: JQuery;
        let $buttonHtml: JQuery;
        let $formGroup: JQuery = $('<div />').attr('class', this.ui.formGroupClass);
        $label = $('<label />').attr({
            class: this.ui.labelClass,
            for: iconData.name
        }).html(iconData.label);

        if (iconData.tooltip !== undefined && iconData.tooltip.length > 0) {
            const $tooltip = this.tooltip(iconData.tooltip);
            $label.append($tooltip);
        }

        $button = $('<button />').prop('type', 'button').attr({
            class: 'btn btn-secondary d-block',
            name: iconData.name,
            id: iconData.data.uniqid,
            role: 'iconpicker'
        }).data('icon', iconData.data.icon);

        $buttonHtml = $('<span id="icon-output" class="text-white" />');
        if (iconData.data.icon) {
            /* if (iconData.data.icon.match(/material/)) {
                console.log(iconData.data.icon);
            } */
            $buttonHtml.append($('<i />').addClass(iconData.data.icon));
        } else {
            $buttonHtml.text('Open the icon picker');
        }

        $button.append($buttonHtml);
        Object.keys(iconData.data).forEach(key => {
            let value = iconData.data[key];
            $button.attr('data-' + key, value);
        });

        $formGroup.append($label).append($button);

        if (iconData.helper !== undefined && iconData.helper.length > 0) {
            const $helper = this.helper(iconData.helper);
            $formGroup.append($helper);
        }

        return $formGroup;
    }

    protected addInput(inputData) {
        let $label: JQuery;
        let $input: JQuery;
        let $formGroup: JQuery = $('<div />').attr('class', this.ui.formGroupClass);

        $label = $('<label />').attr({
            class: this.ui.labelClass,
            for: inputData.name
        }).html(inputData.label);

        if (inputData.tooltip !== undefined && inputData.tooltip.length > 0) {
            const $tooltip = this.tooltip(inputData.tooltip);
            $label.append($tooltip);
        }

        let inputType: string = 'text';
        if (inputData.type !== undefined) {
            inputType = inputData.type;
        }

        $input = $('<input />').prop('type', inputType).attr({
            class: 'form-control form-control-sm',
            name: inputData.name
        });

        if (inputData.pattern !== undefined) {
            $input.prop('pattern', inputData.pattern);
        }

        Object.keys(inputData.data).forEach(key => {
            let value = inputData.data[key];
            $input.attr('data-' + key, value);
        });

        $input.attr('value', inputData.value);

        $formGroup.append($label).append($input);

        if (inputData.helper !== undefined && inputData.helper.length > 0) {
            const $helper = this.helper(inputData.helper);
            $formGroup.append($helper);
        }

        return $formGroup;
    }

    protected addSelect(selectData, options) {
        let $label: JQuery;
        let $select: JQuery;
        let $formGroup: JQuery = $('<div />').attr('class', this.ui.formGroupClass);

        $label = $('<label />').attr({
            class: this.ui.labelClass,
            for: selectData.name
        }).html(selectData.label);

        if (selectData.tooltip !== undefined && selectData.tooltip.length > 0) {
            const $tooltip = this.tooltip(selectData.tooltip);
            $label.append($tooltip);
        }

        $select = $('<select />').attr({
            class: 'form-control',
            name: selectData.name,
            'data-slimselect': 'true'
        });

        Object.keys(selectData.data).forEach(key => {
            let value = selectData.data[key];
            $select.attr('data-' + key, value);
        });

        if (selectData.data.prop === 'plugins') {
            $select.addClass('plugins-select');
        }

        if (typeof (options[0]) === 'string') {
            options.forEach(opt => {
                let $option: JQuery = $('<option />').attr({
                    value: opt
                }).text(opt);
                $select.append($option);
            });
        } else {
            options.forEach(opt => {
                let objText: string = Object.keys(opt)[0];
                let objValue: string = opt[Object.keys(opt)[0]];
                let $option: JQuery = $('<option />').attr({
                    value: objValue
                }).text(objText);
                $select.append($option);
            });
        }

        // if multiple
        if (selectData.name.match(/\[\]$/)) {
            $select.prop('multiple', true);
        }

        // if disabled
        if (selectData.disabled !== undefined && selectData.disabled === true) {
            $select.attr('disabled', 'true');
        }

        $select.val(selectData.value);

        $formGroup.append($label).append($select);

        if (selectData.helper !== undefined && selectData.helper.length > 0) {
            const $helper = this.helper(selectData.helper);
            $formGroup.append($helper);
        }

        return $formGroup;
    }

    protected addTextarea(textareaData) {
        let $label: JQuery;
        let $textarea: JQuery;
        let $formGroup: JQuery = $('<div />').attr('class', this.ui.formGroupClass);

        $label = $('<label />').attr({
            class: this.ui.labelClass,
            for: textareaData.name
        }).html(textareaData.label);

        if (textareaData.tooltip !== undefined && textareaData.tooltip.length > 0) {
            const $tooltip = this.tooltip(textareaData.tooltip);
            $label.append($tooltip);
        }

        $textarea = $('<textarea />').attr({
            class: 'form-control form-control-sm',
            name: textareaData.name
        });

        Object.keys(textareaData.data).forEach(key => {
            let value = textareaData.data[key];
            $textarea.attr('data-' + key, value);
        });

        $textarea.val(textareaData.value);

        $formGroup.append($label).append($textarea);

        if (textareaData.helper !== undefined && textareaData.helper.length > 0) {
            const $helper = this.helper(textareaData.helper);
            $formGroup.append($helper);
        }

        return $formGroup;
    }

    protected buildSelectDefaultValue(value, items) {
        const selectData = {
            data: {
                prop: 'value'
            },
            name: 'value',
            label: 'Default value',
            value: value
        }
        const selectOptions: any = [
            {
                'None': ''
            }
        ];
        items.forEach(opt => {
            let optValue = opt.value;
            var obj = {};
            obj[optValue] = optValue;
            selectOptions.push(obj);
        });

        return this.addSelect(selectData, selectOptions);
    }

    protected enableDeletePopover(data: PopoverDeleteData, fieldsetSelector) {
        $(fieldsetSelector).data(data).find('[data-bs-toggle=popover]').popover({
            html: true,
            container: fieldsetSelector,
            content: () => {
                return $(`<div>
                    <h5>Delete?</h5>
                    <div class="btn-group" role="group" aria-label="Delete?">
                        <button type="button" class="btn btn-sm btn-secondary delete-cancel">No</button>
                        <button type="button" class="btn btn-sm btn-danger delete-confirm">Yes</button>
                    </div>
                </div>`);
            }
        });
    }

    protected appendPlugin($pluginFieldset) {
        $('#tab-plugins').append($pluginFieldset);
        this.refreshComponentSettingsFormPlugins($pluginFieldset);
    }

    protected getAttributeValue(attrArray, attrKey, returnBool = false) {
        const attrObj = attrArray.find(attr => attr.name === attrKey);
        if (attrObj === undefined) {
            return '';
        } else if (returnBool) {
            return attrObj.value === 'true'
        }

        return attrObj.value;
    }

    protected getUniqueValue(value, items) {
        const values = items.map(item => item.value);
        let index: number = 0;
        let outputValue = value;
        while (values.indexOf(outputValue) !== -1) {
            outputValue = value + '-' + index;
            index++;
        }

        return outputValue;
    }

    protected helper(html) {
        return '<p class="small ms-3 mb-0"><i class="icon-lifebuoy ms-n3 me-1"></i>' + html + '</p>';
    }

    protected isPrettyCheckboxEnabled() {
        let outBool = false;
        formGenerator.userForm.plugins.forEach(pl => {
            if (pl.pluginName === 'pretty-checkbox') {
                outBool = true;
            }
        });
        return outBool;
    }

    protected tooltip(text) {
        return `<i class="icon-question-circle ms-2" data-bs-toggle="tooltip" data-bs-html="true" title="${text}"></i>`;
    }

    protected renderSection(tabs: Array<Tabs>, callback: () => void | null = null) {
        const $form = $('<form />').attr({
            id: 'components-settings-form',
            name: 'components-settings-form',
        });
        let $output: any;
        $output = $('<section />').data({
            targetId: this.section.id,
            componentType: this.section.componentType
        });
        if (tabs.length > 0) {
            const $nav: JQuery = $('<nav />');
            const $navTabs: JQuery = $('<div />').addClass('nav nav-tabs small').attr('role', 'tablist');

            let indexNav: number = 0;

            // nav
            tabs.forEach(tab => {
                const $navLink: JQuery = $('<a />').addClass('nav-item nav-link text-body').attr({
                    'data-bs-toggle': 'tab',
                    'href': '#' + tab.id,
                    'role': 'tab',
                    'aria-controls': tab.id
                }).addClass('px-3 px-md-2').html(`${tab.title}`);
                if (indexNav === 0) {
                    $navLink.addClass('active');
                }
                $navTabs.append($navLink);
                $nav.append($navTabs);

                indexNav++;
            });
            $form.append($nav);

            // tabs contents
            const $tabContents: JQuery = $('<div />').addClass('tab-content');
            let indexTabs: number = 0;

            tabs.forEach(tab => {
                const $tabPane: JQuery = $('<div />').addClass('tab-pane fade').attr({
                    id: tab.id,
                    role: 'tabpanel'
                });

                if (indexTabs === 0) {
                    $tabPane.addClass('show active');
                }

                tab.content.forEach($content => {
                    $tabPane.append($content);
                    $tabContents.append($tabPane);
                });
                $form.append($tabContents);
                indexTabs++;
            });
        }/*  else { Form Generator events are based on tabs & will not work without tabs.
            const tab = tabs[0];
            tab.content.forEach($content => {
                $form.append($content);
            });
        } */
        $output.append($form);

        $('#components-settings').html($output);
        this.refreshComponentSettingsFormPlugins();

        if (callback !== null) {
            callback();
        }
    }

    protected makeUniqId(length) {
        var result = '';
        var characters = 'abcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
}
