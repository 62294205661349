import Attribute from '../Interfaces/Attribute';

export default class Utilities {
    static addClass(newclassname: string, attr: string) {

        /* if attr already contains a class we keep it and add newclassname */

        const myRegexp = /class="([^"]+)"/g;
        const out = myRegexp.exec(attr);
        if (out !== null) {
            let new_class = 'class="' + out[1] + ' ' + newclassname + '"';
            new_class = attr.replace(myRegexp, new_class);

            return new_class;
        } else { // if $attr contains no class we add elementClass
            return attr + ' class="' + newclassname + '"';
        }
    }

    static attrArrayToString(attrArray) {
        let tempArray: Array<string> = [];
        attrArray.forEach(attr => {
            tempArray.push(`${attr.name}=${attr.value}`);
        });
        return tempArray.toString();
    }

    static attrArrayToQuotedString(attrArray: Array<Attribute>) {
        let tempArray: Array<string> = [];
        attrArray.forEach(attr => {
            if (typeof (attr.value) === 'string' && attr.value.length > 0) {
                // add quotes
                tempArray.push(`${attr.name}="${attr.value}"`);
            } else {
                // no quote for single attributes
                tempArray.push(`${attr.name}`);
            }
        });
        return tempArray.join(' ');
    }

    static upperCamelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return word.toUpperCase();
        }).replace(/-+/g, '');
    }
}
