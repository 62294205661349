import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Pickatime extends Plugins implements Pluginz {
    public objectName: string = 'Pickatime';
    public pluginName: string = 'pickadate';
    public isjQuery: boolean = true;
    public selector: string;
    public jsConfig: string = 'pickatime';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'format',
            value: 'h:i A'
        },
        {
            name: 'format-submit',
            value: undefined
        },
        {
            name: 'interval',
            value: '30'
        },
        {
            name: 'min',
            value: undefined
        },
        {
            name: 'max',
            value: undefined
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#pickadate-timepicker-example');
        const $legend = $('<legend />').html('Pickatime').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // data-format
        const formatData = {
            data: {
                plugin: 'Pickatime',
                prop: 'dataAttributes',
                attributename: 'format'
            },
            name: 'format',
            label: 'Format',
            tooltip: 'The time output format for the input field value.<br><br>Default: &apos;h:i A&apos;',
            value: this.getAttributeValue('format')
        }
        const $inputFormat = compSettings.addInput(formatData);

        $fieldset.append($inputFormat);

        // data-format-submit
        const formatSubmitData = {
            data: {
                plugin: 'Pickatime',
                prop: 'dataAttributes',
                attributename: 'format-submit'
            },
            name: 'format-submit',
            label: 'Format Submit',
            tooltip: 'Display a human-friendly format and use an alternate one to submit to the server.<br><br>This is done by creating a new hidden input element with the same name attribute as the original with _submit suffix',
            value: this.getAttributeValue('format-submit')
        }
        const $inputFormatSubmit = compSettings.addInput(formatSubmitData);

        $fieldset.append($inputFormatSubmit);

        // data-interval
        const intervalData = {
            data: {
                plugin: 'Pickatime',
                prop: 'dataAttributes',
                attributename: 'interval'
            },
            name: 'interval',
            label: 'Interval',
            tooltip: 'Choose the minutes interval between each time in the list.<br><br>Default: 30',
            value: this.getAttributeValue('interval')
        }
        const $intervalSubmit = compSettings.addInput(intervalData);

        $fieldset.append($intervalSubmit);

        // data-min
        const minTimeData = {
            data: {
                plugin: 'Pickatime',
                prop: 'dataAttributes',
                attributename: 'min'
            },
            name: 'min',
            label: 'Min. time',
            tooltip: 'The earliest time that can be selected..<br><br>Hour, Minute. ie: 08,00<br><br>Default: null',
            helper: '',
            value: this.getAttributeValue('min')
        }
        const $inputMinDate = compSettings.addInput(minTimeData);

        $fieldset.append($inputMinDate);

        // data-max
        const maxTimeData = {
            data: {
                plugin: 'Pickatime',
                prop: 'dataAttributes',
                attributename: 'max'
            },
            name: 'max',
            label: 'Max. time',
            tooltip: 'The latest time that can be selected..<br><br>Hour, Minute. ie: 17,00<br><br>Default: null',
            helper: '',
            value: this.getAttributeValue('max')
        }
        const $inputMaxTime = compSettings.addInput(maxTimeData);

        $fieldset.append($inputMaxTime);

        return $fieldset;
    }
}
