import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Icheck extends Plugins implements Pluginz {
    public objectName: string = 'Icheck';
    public pluginName: string = 'icheck';
    public isjQuery: boolean = true;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = {
        theme: 'square',
        color: 'blue'
    };
    public dataAttributes: Array<Attribute> = [];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#icheck-example');
        const $legend = $('<legend />').html('Icheck').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // replacements
        const selectThemeData = {
            data: {
                plugin: 'Icheck',
                prop: 'replacements',
                replacementkey: 'theme'
            },
            name: 'icheck-theme-data',
            label: 'Theme',
            value: this.replacements.theme
        }
        const selectTheme: Array<string> = ['flat', 'futurico', 'line', 'minimal', 'polaris', 'square'];
        const $selectTheme = compSettings.addSelect(selectThemeData, selectTheme);

        $fieldset.append($selectTheme);

        const selectColorData = {
            data: {
                plugin: 'Icheck',
                prop: 'replacements',
                replacementkey: 'color'
            },
            name: 'icheck-color-data',
            label: 'Color',
            value: this.replacements.color
        }
        const selectColor: Array<string> = ['purple', 'blue', 'green', 'grey', 'orange', 'pink', 'purple', 'red', 'yellow'];
        const $selectColor = compSettings.addSelect(selectColorData, selectColor);

        $fieldset.append($selectColor);

        const $themeHasNoColor = $('<p />').attr({ 'id': 'icheck-nocolor-msg' }).addClass('small ms-3 mb-0').html('<i class="icon-lifebuoy ms-n3 me-1"></i>This theme has no color option').hide();

        $fieldset.append($themeHasNoColor);

        return $fieldset;
    }
}
