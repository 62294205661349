import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class PrettyCheckbox extends Plugins implements Pluginz {
    public objectName: string = 'PrettyCheckbox';
    public pluginName: string = 'pretty-checkbox';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = {};
    public checkboxStyle: string = 'default';
    public radioStyle: string = 'round';
    public fill: string = '';
    public plain: boolean = false;
    public size: string = '';
    public animations: string = '';
    public dataAttributes: Array<Attribute> = [];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#pretty-checkbox-example');
        const $legend = $('<legend />').html('PrettyCheckbox').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        const $info = $('<p />').addClass('alert alert-info has-icon mt-4').html('Here you can manage the global Pretty Checkbox settings.<br><hr /><code>smooth</code> and <code>pulse</code> animations work on every checkbox/radio. The others work only if the checkbox/radio are replaced by icons.<br><hr />To can set your icons and others individual settings select your component in the main panel then go to the <code>component settings => plugins</code> tab.');
        $fieldset.append($info);

        // checkboxStyle
        const selectCheckboxStyleData = {
            data: {
                plugin: 'PrettyCheckbox',
                prop: 'checkboxStyle'
            },
            name: 'checkbox-style',
            label: 'Checkbox Style',
            value: this.checkboxStyle
        }
        const selectCheckboxStyleOptions: any = ['default', 'curve', 'round'];
        const $selectCheckboxStyle = compSettings.addSelect(selectCheckboxStyleData, selectCheckboxStyleOptions);

        $fieldset.append($selectCheckboxStyle);

        // radioStyle
        const selectRadioStyleData = {
            data: {
                plugin: 'PrettyCheckbox',
                prop: 'radioStyle'
            },
            name: 'radio-style',
            label: 'Radio Style',
            value: this.radioStyle
        }
        const selectRadioStyleOptions: any = ['default', 'curve', 'round'];
        const $selectRadioStyle = compSettings.addSelect(selectRadioStyleData, selectRadioStyleOptions);

        $fieldset.append($selectRadioStyle);

        // fill
        const selectFillData = {
            data: {
                plugin: 'PrettyCheckbox',
                prop: 'fill'
            },
            name: 'fill',
            label: 'Fill',
            value: this.fill
        }
        const selectFillOptions: any = ['none', 'fill', 'thick'];
        const $selectFill = compSettings.addSelect(selectFillData, selectFillOptions);

        $fieldset.append($selectFill);

        // plain
        const switchPlainData = {
            checkboxes: [{
                checked: this.plain,
                data: {
                    plugin: 'PrettyCheckbox',
                    prop: 'plain'
                },
                name: 'plain',
                label: '',
                value: true
            }],
            label: 'Plain',
            plugin: 'lcswitch',
            tooltip: 'Remove the border if enabled',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkPlain = compSettings.addCheckboxes(switchPlainData);
        $fieldset.append($checkPlain);

        // size
        const switchSizeData = {
            checkboxes: [{
                checked: this.size,
                data: {
                    plugin: 'PrettyCheckbox',
                    prop: 'size'
                },
                name: 'bigger',
                label: '',
                value: 'bigger'
            }],
            label: 'Size bigger',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkSize = compSettings.addCheckboxes(switchSizeData);
        $fieldset.append($checkSize);

        // animations
        const selectAnimationsData = {
            data: {
                plugin: 'PrettyCheckbox',
                prop: 'animations'
            },
            name: 'animations',
            label: 'Animations',
            value: this.animations
        }
        const selectAnimationsOptions: any = ['none', 'smooth', 'jelly', 'tada', 'rotate', 'pulse'];
        const $selectAnimations = compSettings.addSelect(selectAnimationsData, selectAnimationsOptions);

        $fieldset.append($selectAnimations);

        return $fieldset;
    }
}
