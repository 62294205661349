import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Modal extends Plugins implements Pluginz {
    public objectName: string = 'Modal';
    public pluginName: string = 'modal';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = {};
    public title: string = '';
    public titleClass: string = '';
    public titleTag: string = '';
    public animation: string = '';
    public blur: boolean = true;
    public dataAttributes: Array<Attribute> = [];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#modal-example');
        const $legend = $('<legend />').html('Modal').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // title
        const titleData = {
            data: {
                plugin: 'Modal',
                prop: 'title'
            },
            name: 'title',
            label: 'Title',
            tooltip: 'Title (string or HTML code) of the modal header. If empty the modal won\'t have any header.',
            value: this.title
        }
        const $inputTitle = compSettings.addInput(titleData);

        $fieldset.append($inputTitle);

        // title-class
        const titleClassData = {
            data: {
                plugin: 'Modal',
                prop: 'titleClass'
            },
            name: 'title-class',
            label: 'Title class',
            tooltip: 'CSS class of the title.',
            value: this.titleClass
        }
        const $inputTitleClass = compSettings.addInput(titleClassData);

        $fieldset.append($inputTitleClass);

        // title-tag
        const selectTitleTagData = {
            data: {
                plugin: 'Modal',
                prop: 'titleTag'
            },
            name: 'title-tag',
            label: 'Title heading tag',
            tooltip: 'The HTML heading tag name of the title.',
            value: this.titleTag
        }
        const selectTitleTagOptions: any = [
            {
                '<h1 />': 'h1'
            },
            {
                '<h2 />': 'h2'
            },
            {
                '<h3 />': 'h3'
            },
            {
                '<h4 />': 'h4'
            },
            {
                '<h5 />': 'h5'
            },
            {
                '<h6 />': 'h6'
            },
        ];
        const $selectTitleTag = compSettings.addSelect(selectTitleTagData, selectTitleTagOptions);

        $fieldset.append($selectTitleTag);

        // animation
        const selectAnimationData = {
            data: {
                plugin: 'Modal',
                prop: 'animation'
            },
            name: 'animation',
            label: 'Animation',
            tooltip: 'The entrance animation for the modal.',
            value: this.animation
        }
        const selectAnimationOptions: any = ['fade-in', 'slide-in-top', 'slide-in-left', 'slide-in-right', 'slide-in-bottom', 'scale-in', 'flip-in-horizontal', 'flip-in-vertical'];
        const $selectAnimation = compSettings.addSelect(selectAnimationData, selectAnimationOptions);

        $fieldset.append($selectAnimation);

        // blur
        const switchBlurData = {
            checkboxes: [{
                checked: this.blur,
                data: {
                    plugin: 'Modal',
                    prop: 'blur'
                },
                name: 'blur',
                label: '',
                value: true
            }],
            label: 'Blur',
            tooltip: 'Blur the page behind the modal overlay if enabled',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkBlur = compSettings.addCheckboxes(switchBlurData);
        $fieldset.append($checkBlur);

        return $fieldset;
    }
}
