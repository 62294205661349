import Components from '../Interfaces/Components';

export default class Html implements Components {
    public componentType: string = 'html';
    public index        : number;
    public value        : string;

    constructor(index, value = '<p>Enter any HTML code here</p>') {
        this.index = index;
        this.value = value;
    }
}
