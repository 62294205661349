declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';

export default class Dependent extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // documentation
        const $doc = $('<p />').addClass('mt-4').html(this.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#dependent-fields-example'));
        $mainTab.push($doc);

        // Name
        const selectData = {
            data: {
                prop: 'name'
            },
            name: 'parent-name',
            label: 'Parent field',
            value: this.component.name
        }

        // get the available parent fields from the DOM
        const selectOptions: Array<string> = [];
        $('#fg-form :input').each(function (index, el) {
            if (!$(el).is('button') && !$(el).is('textarea')) {
                const elName = el.name.replace('[]', '');
                if (selectOptions.indexOf(elName) === -1) {
                    selectOptions.push(elName);
                }
            }
        });
        const $selectName = this.addSelect(selectData, selectOptions);

        $mainTab.push($selectName);

        // Value
        const valueData = {
            data: {
                prop: 'value'
            },
            label: 'Parent field value(s)',
            name: 'parent-value',
            placeholder: `Enter comma-separated values. The dependent fields will show if the parent field's value is one of these.`,
            value: this.component.value
        }
        const $inputValue = this.addInput(valueData);
        $mainTab.push($inputValue);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        this.renderSection(this.tabs);
    }
}
