import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class NiceCheck extends Plugins implements Pluginz {
    public objectName: string = 'NiceCheck';
    public pluginName: string = 'nice-check';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = {
        skin: 'blue'
    };
    public dataAttributes: Array<Attribute> = [];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#nice-check-example');
        const $legend = $('<legend />').html('NiceCheck').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // replacements
        const selectData = {
            data: {
                plugin: 'NiceCheck',
                prop: 'replacements',
                replacementkey: 'skin'
            },
            name: 'nice-check-data',
            label: 'Skin',
            value: this.replacements.skin
        }
        const selectSkin: Array<string> = ['black', 'blue-gray', 'blue', 'cyan', 'gray-dark', 'gray', 'green', 'indigo', 'orange', 'pink', 'purple', 'red', 'teal', 'white', 'yellow'];
        const $selectSkin = compSettings.addSelect(selectData, selectSkin);

        $fieldset.append($selectSkin);

        return $fieldset;
    }
}
