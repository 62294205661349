import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Popover extends Plugins implements Pluginz {
    public objectName: string = 'Popover';
    public pluginName: string = 'popover';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = {};
    public dataAttributes: Array<Attribute> = [
        {
            name: 'animation',
            value: 'fade'
        },
        {
            name: 'arrow',
            value: 'true'
        },
        {
            name: 'backdrop',
            value: 'false'
        },
        {
            name: 'delay',
            value: '0'
        },
        {
            name: 'max-width',
            value: ''
        },
        {
            name: 'placement',
            value: 'auto'
        },
        {
            name: 'theme',
            value: 'light'
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        let checked;
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#modal-example');
        const $legend = $('<legend />').html('Popover').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // animation
        const selectAnimationData = {
            data: {
                plugin: 'Popover',
                prop: 'dataAttributes',
                attributename: 'animation'
            },
            name: 'animation',
            label: 'Animation',
            tooltip: 'The popover show / hide animation',
            value: this.getAttributeValue('animation')
        }
        const selectAnimationOptions: any = ['fade', 'shift-away', 'shift-toward', 'scale', 'perspective'];
        const $selectAnimation = compSettings.addSelect(selectAnimationData, selectAnimationOptions);

        $fieldset.append($selectAnimation);

        // arrow
        checked = this.getAttributeChecked('arrow');
        const switchArrowData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Popover',
                    prop: 'dataAttributes',
                    attributename: 'arrow'
                },
                name: 'arrow',
                label: '',
                value: true
            }],
            label: 'Arrow',
            tooltip: 'The arrow that points toward the element.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkArrow = compSettings.addCheckboxes(switchArrowData);
        $fieldset.append($checkArrow);

        // backdrop
        checked = this.getAttributeChecked('backdrop');
        const switchBackdropData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Popover',
                    prop: 'dataAttributes',
                    attributename: 'backdrop'
                },
                name: 'backdrop',
                label: '',
                value: true
            }],
            label: 'Backdrop',
            tooltip: 'Covers the page with a semi-opaque background.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkBackdrop = compSettings.addCheckboxes(switchBackdropData);
        $fieldset.append($checkBackdrop);

        // delay
        const delayData = {
            data: {
                plugin: 'Popover',
                prop: 'dataAttributes',
                attributename: 'delay'
            },
            name: 'delay',
            label: 'Delay',
            tooltip: 'The delay in milliseconds before opening the popover.',
            value: this.getAttributeValue('delay')
        }
        const $inputDelay = compSettings.addInput(delayData);

        $fieldset.append($inputDelay);

        // max-width
        const maxWidthData = {
            data: {
                plugin: 'Popover',
                prop: 'dataAttributes',
                attributename: 'max-width'
            },
            name: 'max-width',
            label: 'Max. width',
            tooltip: 'The maximum width of the popover. All valid css units are accepted (px, rem, vw, ...).',
            value: this.getAttributeValue('max-width')
        }
        const $inputMaxWidth = compSettings.addInput(maxWidthData);

        $fieldset.append($inputMaxWidth);

        // placement
        const selectPlacementData = {
            data: {
                plugin: 'Popover',
                prop: 'dataAttributes',
                attributename: 'placement'
            },
            name: 'placement',
            label: 'Placement',
            tooltip: 'The preferred placement of the tippy. Note that Popper\'s flip modifier can change this to the opposite placement if it has more space.',
            value: this.getAttributeValue('placement')
        }
        const selectPlacementOptions: any = ['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'auto', 'auto-start', 'auto-end'];
        const $selectPlacement = compSettings.addSelect(selectPlacementData, selectPlacementOptions);

        $fieldset.append($selectPlacement);

        // theme
        const selectThemeData = {
            data: {
                plugin: 'Popover',
                prop: 'dataAttributes',
                attributename: 'theme'
            },
            name: 'theme',
            label: 'Theme',
            value: this.getAttributeValue('theme')
        }
        const selectThemeOptions: any = ['light', 'light-border', 'material', 'translucent', 'null'];
        const $selectTheme = compSettings.addSelect(selectThemeData, selectThemeOptions);

        $fieldset.append($selectTheme);

        return $fieldset;
    }
}
