declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';

export default class FileuploaderSettings extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // Name
        const nameData = {
            data: {
                prop: 'name'
            },
            label: 'Name',
            name: 'name',
            value: this.component.name,
            pattern: '[a-z0-9_-]+',
            tooltip: 'alphanumeric characters, hyphens and underscores only.'
        }
        const $inputName = this.addInput(nameData);
        $mainTab.push($inputName);

        // Label
        const labelData = {
            data: {
                prop: 'label'
            },
            label: 'Label',
            name: 'label',
            value: this.component.label
        }
        const $inputLabel = this.addInput(labelData);
        $mainTab.push($inputLabel);

        // HelpText
        const helperData = {
            data: {
                prop: 'helper'
            },
            label: 'Helper',
            name: 'helper',
            tooltip: 'Helper text displayed below the field.',
            value: this.component.helper
        }
        const $inputHelper = this.addInput(helperData);
        $mainTab.push($inputHelper);

        // jsConfig
        const confSelectData = {
            data: {
                prop: 'xml'
            },
            name: 'xml-data',
            label: 'Uploader',
            value: this.component.xml
        }
        const confSelectOptions: Array<any> = [
            {
                'Default': 'default'
            },
            {
                'Image upload': 'image-upload'
            },
            {
                'Drag & drop': 'drag-and-drop'
            }
        ];
        const $selectConf = this.addSelect(confSelectData, confSelectOptions);

        $mainTab.push($selectConf);

        // uploadDir
        const uploadDirData = {
            data: {
                prop: 'uploadDir'
            },
            label: 'upload Dir.',
            name: 'uploaddir',
            helper: 'Path from the root of your project. ie: <code>/file-uploads/</code>',
            value: this.component.uploadDir
        }
        const $inputUploadDir = this.addInput(uploadDirData);
        $mainTab.push($inputUploadDir);

        // limit
        const limitData = {
            data: {
                prop: 'limit'
            },
            label: 'Limit',
            name: 'limit',
            tooltip: 'Maximum number of uploaded files',
            value: this.component.limit
        }
        const $inputLimit = this.addInput(limitData);
        $mainTab.push($inputLimit);

        // extensions
        const extensionsData = {
            data: {
                prop: 'extensions'
            },
            label: 'Extensions',
            name: 'extensions',
            helper: 'Comma separated list of the allowed extensions.ie:<br><code class="small">jpg, jpeg, png, gif</code><br>Leave empty to allow all.',
            value: this.component.extensions
        }
        const $inputExtensions = this.addInput(extensionsData);
        $mainTab.push($inputExtensions);

        // fileMaxSize
        const fileMaxSizeData = {
            data: {
                prop: 'fileMaxSize'
            },
            label: 'File max size',
            name: 'filemaxsize',
            helper: 'maximal file size in MB',
            value: this.component.fileMaxSize
        }
        const $inputFileMaxSize = this.addInput(fileMaxSizeData);
        $mainTab.push($inputFileMaxSize);

        const $fieldset = $('<fieldset />').addClass('p-2 m-n2 bg-light');
        const $legend = $('<legend />').addClass('h5 fw-light pt-5').html('Image upload settings');
        $fieldset.append($legend)

        // thumbnails
        const thumbnailsData = {
            checkboxes: [{
                checked: 'checked',
                data: {
                    prop: 'thumbnails'
                },
                label: '',
                name: 'thumbnails',
                value: true
            }],
            label: 'Thumbnails',
            tooltip: 'Defines whether if the uploader creates thumbnails or not',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkThumbnails = this.addCheckboxes(thumbnailsData);
        $fieldset.append($checkThumbnails);

        // editor
        const editorData = {
            checkboxes: [{
                checked: 'checked',
                data: {
                    prop: 'editor'
                },
                label: '',
                name: 'editor',
                value: true
            }],
            label: 'Editor',
            tooltip: 'Uploaded images can be clicked & edited by user',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkEditor = this.addCheckboxes(editorData);
        $fieldset.append($checkEditor);

        // width
        const widthImgData = {
            data: {
                prop: 'widthImg'
            },
            label: 'Width',
            name: 'width-img',
            helper: 'Maximum image width in px. Empty = no limit.',
            value: this.component.widthImg
        }
        const $inputWidthImg = this.addInput(widthImgData);
        $fieldset.append($inputWidthImg);

        // height
        const heightImgData = {
            data: {
                prop: 'heightImg'
            },
            label: 'Height',
            name: 'height-img',
            helper: 'Maximum image height in px. Empty = no limit.',
            value: this.component.heightImg
        }
        const $inputHeightImg = this.addInput(heightImgData);
        $fieldset.append($inputHeightImg);

        // crop
        const cropData = {
            checkboxes: [{
                checked: 'checked',
                data: {
                    prop: 'crop'
                },
                label: '',
                name: 'crop',
                value: true
            }],
            label: 'Crop',
            tooltip: 'Crop image to fit the given width & height',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkCrop = this.addCheckboxes(cropData);
        $fieldset.append($checkCrop);

        $mainTab.push($fieldset);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        this.renderSection(this.tabs);
    }
}
