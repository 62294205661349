import Components from '../Interfaces/Components';

export default class Heading implements Components {
    public clazz: string;
    public componentType: string = 'heading';
    public index: number;
    public type: string; // h1 ... h6
    public value: string;

    constructor(index, type = 'h1', value = 'My title', clazz = '') {
        this.clazz = clazz;
        this.index = index;
        this.type = type;
        this.value = value;
    }
}
