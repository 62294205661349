declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';

export default class Dependentend extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // documentation
        const $nothing = $('<p />').addClass('mt-4 text-muted').html('Nothing to configure here');
        $mainTab.push($nothing);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        this.renderSection(this.tabs);
    }
}
