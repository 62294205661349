import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Select2 extends Plugins implements Pluginz {
    public objectName: string = 'Select2';
    public pluginName: string = 'select2';
    public isjQuery: boolean = true;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'close-on-select',
            value: 'true'
        },
        {
            name: 'dropdown-auto-width',
            value: 'false'
        },
        {
            name: 'minimum-results-for-search',
            value: '0'
        },
        {
            name: 'tags',
            value: 'false'
        },
        {
            name: 'theme',
            value: 'default'
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        let checked;
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#select2-example');
        const $legend = $('<legend />').html('Select2').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);


        // Close on select
        checked = this.getAttributeChecked('close-on-select');
        const closeOnSelectData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Select2',
                    prop: 'dataAttributes',
                    attributename: 'close-on-select'
                },
                name: 'close-on-select',
                label: '',
                tooltip: 'Controls whether the dropdown is closed after a selection is made.',
                value: true
            }],
            label: 'Close on select',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $closeOnSelectCheck = compSettings.addCheckboxes(closeOnSelectData);
        $fieldset.append($closeOnSelectCheck);

        // Dropdown auto width
        checked = this.getAttributeChecked('dropdown-auto-width');
        const dropdownAutoWidthData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Select2',
                    prop: 'dataAttributes',
                    attributename: 'dropdown-auto-width'
                },
                name: 'dropdown-auto-width',
                label: '',
                tooltip: '',
                value: true
            }],
            label: 'Dropdown auto width',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $dropdownAutoWidthCheck = compSettings.addCheckboxes(dropdownAutoWidthData);
        $fieldset.append($dropdownAutoWidthCheck);

        // Minimum results for search
        const minimumResultsForSearchData = {
            data: {
                plugin: 'Select2',
                prop: 'dataAttributes',
                attributename: 'minimum-results-for-search'
            },
            name: 'minimum-results-for-search',
            label: 'Minimum results for search',
            tooltip: 'The minimum number of results required to display the search box.',
            helper: 'Type <em>Infinity</em> to permanently remove the search box',
            value: this.getAttributeValue('minimum-results-for-search')
        }
        const $minimumResultsForSearchInput = compSettings.addInput(minimumResultsForSearchData);

        $fieldset.append($minimumResultsForSearchInput);

        // Tags
        checked = this.getAttributeChecked('tags');
        const tagsData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Select2',
                    prop: 'dataAttributes',
                    attributename: 'tags'
                },
                name: 'tags',
                label: '',
                tooltip: 'Used to enable free text responses.',
                value: true
            }],
            label: 'Tags',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $tagsCheck = compSettings.addCheckboxes(tagsData);
        $fieldset.append($tagsCheck);

        // Theme
        const themeData = {
            data: {
                plugin: 'Select2',
                prop: 'dataAttributes',
                attributename: 'theme'
            },
            name: 'theme',
            label: 'Theme',
            value: this.getAttributeValue('theme')
        }
        const themeOptions: Array<string> = ['default', 'classic'];
        const $themeSelect = compSettings.addSelect(themeData, themeOptions);

        $fieldset.append($themeSelect);

        return $fieldset;
    }
}
