import Button from './Components/Button';
import Buttongroup from './Components/Buttongroup';
import Checkbox from './Components/Checkbox';
import Dependent from './Components/Dependent';
import Dependentend from './Components/Dependentend';
import Fileuploader from './Components/Fileuploader';
import Hcaptcha from './Components/Hcaptcha';
import Heading from './Components/Heading';
import Html from './Components/Html';
import Input from './Components/Input';
import Paragraph from './Components/Paragraph';
import Radio from './Components/Radio';
import Recaptcha from './Components/Recaptcha';
import Select from './Components/Select';
import Textarea from './Components/Textarea';

export const availableComponents: any = {
    Button,
    Buttongroup,
    Checkbox,
    Dependent,
    Dependentend,
    Fileuploader,
    Hcaptcha,
    Heading,
    Html,
    Input,
    Paragraph,
    Radio,
    Recaptcha,
    Select,
    Textarea
};
