import Components from '../Interfaces/Components';
import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';

import ComponentsMain from './ComponentsMain';

export default class Button extends ComponentsMain implements Components {
    public attr: Array<Attribute>;
    public clazz: string;
    public center: string;
    public componentType: string = 'button';
    public icon: string;
    public iconPosition: string;
    public index: number;
    public label: string;
    public name: string;
    public plugins: Array<Pluginz> = [];
    public type: string;
    public value: string;

    constructor(index, type = 'submit', name = 'button', value = '', label = 'Button', attr = [], clazz = 'btn btn-success', center = 'false', icon = '', iconPosition = 'before') {
        super();
        this.attr = attr;
        this.center = center;
        this.clazz = clazz;
        this.icon = icon;
        this.iconPosition = iconPosition;
        this.index = index;
        this.label = `${label}`;
        this.name = `${name}-${index}`;
        this.type = type;
        this.value = value;

        // merge clazz with attributes for PHP Form Builder
        this.attr = this.mergeAttributes(this);
    }
}
