import Components from '../Interfaces/Components';

export default class Paragraph implements Components {
    public clazz        : string;
    public componentType: string = 'paragraph';
    public index        : number;
    public value        : string;

    constructor(index, value = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', clazz = '') {
        this.clazz = clazz;
        this.index = index;
        this.value = value;
    }
}
