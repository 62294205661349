import Components from '../Interfaces/Components';
import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import SelectOption from '../Interfaces/SelectOption';

import ComponentsMain from './ComponentsMain';

export default class Select extends ComponentsMain implements Components {
    public attr         : Array<Attribute>;
    public clazz        : string;
    public componentType: string = 'select';
    public helper       : string = '';
    public index        : number;
    public label        : string;
    public name         : string;
    public placeholder  : string;
    public plugins      : Array<Pluginz> = [];
    public value        : string;
    public width        : string;

    public selectOptions: Array<SelectOption> = [
        {
            index: 1,
            text: 'Option 1',
            value: 'option-1',
            groupname: ''
        },
        {
            index: 2,
            text: 'Option 2',
            value: 'option-2',
            groupname: ''
        }
    ];

    constructor(index, name = 'select', value = '', label = 'Select', attr = [], clazz = '', placeholder = '', width = '100%') {
        super();
        this.attr        = attr;
        this.clazz       = clazz;
        this.index       = index;
        this.label       = `${label} ${index}`;
        this.name        = `${name}-${index}`;
        this.placeholder = placeholder;
        this.value       = value;
        this.width       = width;

        // merge clazz & placeholder with attributes for PHP Form Builder
        this.attr = this.mergeAttributes(this);
    }
}
