import Attribute from '../Interfaces/Attribute';

export default class ComponentSettings {
    public dataAttributes: Array<Attribute> = [];

    protected getAttributeChecked(attrName) {
        return this.dataAttributes.find(attr => attr.name === attrName).value === 'true';
    }

    protected getAttributeValue(attrName) {
        const attrObject = this.dataAttributes.find(attr => attr.name === attrName);

        return attrObject.value;
    }

    protected getJsClass(isjQuery) {
        if (isjQuery) {
            return ' jquery';
        }

        return ' vanilla-js';
    }
}
