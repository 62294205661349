import Components from '../Interfaces/Components';

export default class Dependent implements Components {
    public componentType: string = 'dependentend';
    public index: number;
    public name: string; // not used anywhere
    public value: string; // not used anywhere

    constructor(index, name = '', value = '') {
        this.index = index;
        this.name = name;
        this.value = value;
    }
}
