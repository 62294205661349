import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Pickadate extends Plugins implements Pluginz {
    public objectName: string = 'Pickadate';
    public pluginName: string = 'pickadate';
    public isjQuery: boolean = true;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'format',
            value: 'mmm dd, yyyy'
        },
        {
            name: 'format-submit',
            value: null
        },
        {
            name: 'select-years',
            value: 'true'
        },
        {
            name: 'select-months',
            value: 'true'
        },
        {
            name: 'min',
            value: null
        },
        {
            name: 'max',
            value: null
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        let checked;
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#pickadate-example');
        const $legend = $('<legend />').html('Pickadate').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);


        // data-format
        const formatData = {
            data: {
                plugin: 'Pickadate',
                prop: 'dataAttributes',
                attributename: 'format'
            },
            name: 'format',
            label: 'Format',
            tooltip: 'The date output format for the input field value.<br><br>Default: &apos;mmm dd, yyyy&apos;',
            value: this.getAttributeValue('format')
        }
        const $inputFormat = compSettings.addInput(formatData);

        $fieldset.append($inputFormat);

        // data-format-submit
        const formatSubmitData = {
            data: {
                plugin: 'Pickadate',
                prop: 'dataAttributes',
                attributename: 'format-submit'
            },
            name: 'format-submit',
            label: 'Format Submit',
            tooltip: 'Display a human-friendly format and use an alternate one to submit to the server.<br><br>This is done by creating a new hidden input element with the same name attribute as the original with _submit suffix',
            value: this.getAttributeValue('format-submit')
        }
        const $inputFormatSubmit = compSettings.addInput(formatSubmitData);

        $fieldset.append($inputFormatSubmit);

        // data-select-years
        checked = this.getAttributeChecked('select-years');
        const switchData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Pickadate',
                    prop: 'dataAttributes',
                    attributename: 'select-years'
                },
                name: 'select-years',
                label: '',
                tooltip: 'Display select menu to pick the year',
                value: true
            }],
            label: 'Select years',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkSelectYears = compSettings.addCheckboxes(switchData);
        $fieldset.append($checkSelectYears);

        // data-select-months
        checked = this.getAttributeChecked('select-months');
        const switchData2 = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'Pickadate',
                    prop: 'dataAttributes',
                    attributename: 'select-months'
                },
                name: 'select-months',
                label: '',
                tooltip: 'Display select menu to pick the month',
                value: true
            }],
            label: 'Select months',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkSelectMonths = compSettings.addCheckboxes(switchData2);
        $fieldset.append($checkSelectMonths);

        // data-min
        const minDateData = {
            data: {
                plugin: 'Pickadate',
                prop: 'dataAttributes',
                attributename: 'min'
            },
            name: 'min',
            label: 'Min. date',
            tooltip: 'The earliest date that can be selected..<br><br>The date must be a string in Javascript Date Object format. ie: 2020-01-24<br><br>Default: null',
            helper: '<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date" target="_blank">View available formats <i class="icon-outlink ms-1"></i></a>',
            value: this.getAttributeValue('min')
        }
        const $inputMinDate = compSettings.addInput(minDateData);

        $fieldset.append($inputMinDate);

        // data-max
        const maxDateData = {
            data: {
                plugin: 'Pickadate',
                prop: 'dataAttributes',
                attributename: 'max'
            },
            name: 'max',
            label: 'Max. date',
            tooltip: 'The latest date that can be selected..<br><br>The date must be a string in Javascript Date Object format. ie: 2020-01-24<br><br>Default: null',
            helper: '<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date" target="_blank">View available formats <i class="icon-outlink ms-1"></i></a>',
            value: this.getAttributeValue('max')
        }
        const $inputMaxDate = compSettings.addInput(maxDateData);

        $fieldset.append($inputMaxDate);

        return $fieldset;
    }
}
