import Components from '../Interfaces/Components';

export default class Recaptcha implements Components {
    public componentType: string = 'recaptcha';
    public index        : number;
    public privatekey   : string;
    public publickey    : string;

    constructor(index) {
        this.index      = index;
        this.privatekey = '';
        this.publickey  = '';
    }
}
