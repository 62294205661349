import Autocomplete from './Plugins/Autocomplete';
import BootstrapInputSpinner from './Plugins/BootstrapInputSpinner';
import BootstrapSelect from './Plugins/BootstrapSelect';
import Colorpicker from './Plugins/Colorpicker';
import Formvalidation from './Plugins/Formvalidation';
import Icheck from './Plugins/Icheck';
import IntlTelInput from './Plugins/IntlTelInput';
import Ladda from './Plugins/Ladda';
import Litepicker from './Plugins/Litepicker';
import MaterialDatepicker from './Plugins/MaterialDatepicker';
import MaterialTimepicker from './Plugins/MaterialTimepicker';
import Modal from './Plugins/Modal';
import NiceCheck from './Plugins/NiceCheck';
import Passfield from './Plugins/Passfield';
import Pickadate from './Plugins/Pickadate';
import Pickatime from './Plugins/Pickatime';
import Popover from './Plugins/Popover';
import PrettyCheckbox from './Plugins/PrettyCheckbox';
import Select2 from './Plugins/Select2';
import Slimselect from './Plugins/Slimselect';
import Tinymce from './Plugins/Tinymce';
import Tooltip from './Plugins/Tooltip';

export const availablePlugins = {
    button: [Ladda],
    buttongroup: [Ladda],
    checkbox: [],
    form: [Formvalidation, Icheck, Modal, NiceCheck, Popover, PrettyCheckbox],
    input: [Autocomplete, BootstrapInputSpinner, Colorpicker, IntlTelInput, Litepicker, MaterialDatepicker, MaterialTimepicker, Passfield, Pickadate, Pickatime, Tooltip],
    radio: [],
    select: [BootstrapSelect, Select2, Slimselect],
    textarea: [Tinymce]
};
// elements plugins
// Autocomplete, BootstrapInputSpinner, BootstrapSelect, Colorpicker, ImagePicker, IntlTelInput, Ladda, LcSwitch, Litepicker, MaterialDatepicker, MaterialTimepicker, Passfield, Pickadate, Pickatime, Select2, Tinymce, WordCharacterCount

// special plugins
// FileUploader, invisibleRecaptcha

// global plugins
// FormValidation, Icheck, Modal, NiceCheck, Popover
