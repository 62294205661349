export const componentSettingsDefaultConfig = {
    ui: {
        addButtonsClass: "btn btn-sm bg-success text-white fw-light",
        collapsibleButtonsClass: "btn btn-sm small btn-link fw-light text-decoration-none border-0 p-0 text-body dropdown-toggle",
        fieldsetClass: "py-2 mt-2 mx-n2 px-2 bg-light border",
        formCheckClass: "form-check py-1",
        formGroupClass: "form-group mb-0 py-1",
        labelClass: "text-secondary small mb-0",
        lcswitchGroupClass: "form-group d-lg-flex justify-content-lg-between align-items-baseline mb-0 py-1",
        legendClass: "text-gray-600 bg-gray-200 d-inline-flex align-items-center w-auto px-2 py-1 mb-n2 border fw-light h6",
        niceCheckGroupClass: "form-group d-lg-flex justify-content-lg-between flex-wrap mx-n3 py-1",
        topButtonsClass: "btn btn-sm bg-gray-500 text-white fw-light"
    }
}
