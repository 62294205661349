import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class BootstrapInputSpinner extends Plugins implements Pluginz {
    public objectName: string = 'BootstrapInputSpinner';
    public pluginName: string = 'bootstrap-input-spinner';
    public isjQuery: boolean = true;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'input-spinner',
            value: 'true'
        },
        {
            name: 'buttons-clazz',
            value: 'btn-outline-secondary'
        },
        {
            name: 'min',
            value: ''
        },
        {
            name: 'max',
            value: ''
        },
        {
            name: 'step',
            value: ''
        },
        {
            name: 'decimals',
            value: ''
        },
        {
            name: 'suffix',
            value: ''
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#bootstrap-input-spinner-example');
        const $legend = $('<legend />').html('Bootstrap input spinner').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // data-buttons-clazz
        const buttonsClazzData = {
            data: {
                plugin: 'BootstrapInputSpinner',
                prop: 'dataAttributes',
                attributename: 'buttons-clazz'
            },
            name: 'buttons-clazz',
            label: 'Buttons class',
            tooltip: 'CSS class for the "-" &amp; "+" buttons',
            value: this.getAttributeValue('buttons-clazz')
        }
        const $inputButtonsClazz = compSettings.addInput(buttonsClazzData);
        $fieldset.append($inputButtonsClazz);

        // data-min
        const minData = {
            data: {
                plugin: 'BootstrapInputSpinner',
                prop: 'dataAttributes',
                attributename: 'min'
            },
            name: 'min',
            label: 'Min',
            tooltip: 'Minimum value for the input.',
            type: 'number',
            value: this.getAttributeValue('min')
        }
        const $inputMin = compSettings.addInput(minData);
        $fieldset.append($inputMin);

        // data-max
        const maxData = {
            data: {
                plugin: 'BootstrapInputSpinner',
                prop: 'dataAttributes',
                attributename: 'max'
            },
            name: 'max',
            label: 'Max',
            tooltip: 'Maximum value for the input.',
            type: 'number',
            value: this.getAttributeValue('max')
        }
        const $inputMax = compSettings.addInput(maxData);
        $fieldset.append($inputMax);

        // data-step
        const stepData = {
            data: {
                plugin: 'BootstrapInputSpinner',
                prop: 'dataAttributes',
                attributename: 'step'
            },
            name: 'step',
            label: 'Step',
            tooltip: 'increment value for each click on -/+',
            type: 'number',
            value: this.getAttributeValue('step')
        }
        const $inputStep = compSettings.addInput(stepData);
        $fieldset.append($inputStep);

        // data-decimals
        const decimalsData = {
            data: {
                plugin: 'BootstrapInputSpinner',
                prop: 'dataAttributes',
                attributename: 'decimals'
            },
            name: 'decimals',
            label: 'Decimals',
            tooltip: 'Number of decimals for the input value',
            type: 'number',
            value: this.getAttributeValue('decimals')
        }
        const $inputDecimals = compSettings.addInput(decimalsData);
        $fieldset.append($inputDecimals);

        // data-suffix
        const suffixData = {
            data: {
                plugin: 'BootstrapInputSpinner',
                prop: 'dataAttributes',
                attributename: 'suffix'
            },
            name: 'suffix',
            label: 'Suffix',
            tooltip: 'Suffix string to append to the input',
            value: this.getAttributeValue('suffix')
        }
        const $inputSuffix = compSettings.addInput(suffixData);
        $fieldset.append($inputSuffix);

        return $fieldset;
    }
}
