import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Slimselect extends Plugins implements Pluginz {
    public objectName: string = 'Slimselect';
    public pluginName: string = 'slimselect';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'slimselect',
            value: 'true'
        },
        {
            name: 'allow-deselect',
            value: 'true'
        },
        {
            name: 'allow-deselect-option',
            value: 'false'
        },
        {
            name: 'addable',
            value: 'false'
        },
        {
            name: 'limit',
            value: '0'
        },
        {
            name: 'show-search',
            value: 'true'
        },
        {
            name: 'search-text',
            value: 'No Results'
        },
        {
            name: 'search-placeholder',
            value: 'Search ...'
        },
        {
            name: 'close-on-select',
            value: 'true'
        },
        {
            name: 'select-by-group',
            value: 'false'
        },
        {
            name: 'hide-selected-option',
            value: 'false'
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#slimselect-example');
        const $legend = $('<legend />').html('Slimselect').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // allow-deselect
        const allowDeselectData = {
            checkboxes: [{
                checked: this.getAttributeChecked('allow-deselect'),
                data: {
                    plugin: 'Slimselect',
                    prop: 'dataAttributes',
                    attributename: 'allow-deselect'
                },
                name: 'allow-deselect',
                label: '',
                tooltip: 'This will allow you to deselect a value on a single select dropdown.<br>Be sure to have an empty option data placeholder so slimselect has an empty string value to select.',
                value: true
            }],
            label: 'Allow deselect',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $allowDeselectCheck = compSettings.addCheckboxes(allowDeselectData);
        $fieldset.append($allowDeselectCheck);

        // allow-deselect-option
        const allowDeselectOptionData = {
            checkboxes: [{
                checked: this.getAttributeChecked('allow-deselect-option'),
                data: {
                    plugin: 'Slimselect',
                    prop: 'dataAttributes',
                    attributename: 'allow-deselect-option'
                },
                name: 'allow-deselect-option',
                label: '',
                tooltip: 'This will allow you to deselect a value in the dropdown. By simple clicking the option again.<br>Be sure to have an empty option data placeholder so slimselect has an empty string value to select.',
                value: true
            }],
            label: 'Allow deselect option',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $allowDeselectOptionCheck = compSettings.addCheckboxes(allowDeselectOptionData);
        $fieldset.append($allowDeselectOptionCheck);

        // addable
        const addableData = {
            checkboxes: [{
                checked: this.getAttributeChecked('addable'),
                data: {
                    plugin: 'Slimselect',
                    prop: 'dataAttributes',
                    attributename: 'addable'
                },
                name: 'addable',
                label: '',
                tooltip: 'Slim select has the ability to dynamically add options via the search input field.<br>The added values are automatically sanitized by PHP Form Builder using dompurify.',
                value: true
            }],
            label: 'Addable',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $addableCheck = compSettings.addCheckboxes(addableData);
        $fieldset.append($addableCheck);

        // limit
        const limitData = {
            data: {
                plugin: 'Slimselect',
                prop: 'dataAttributes',
                attributename: 'limit'
            },
            name: 'limit',
            label: 'Limit',
            tooltip: 'When using multi select you can set a limit on the amount of selections you can have.<br>Zero is no-limit.',
            value: this.getAttributeValue('limit')
        }
        const $limitInput = compSettings.addInput(limitData);

        $fieldset.append($limitInput);

        // show-search
        const showSearchData = {
            checkboxes: [{
                checked: this.getAttributeChecked('show-search'),
                data: {
                    plugin: 'Slimselect',
                    prop: 'dataAttributes',
                    attributename: 'show-search'
                },
                name: 'show-search',
                label: '',
                tooltip: 'Decide whether or not to show the search.',
                value: true
            }],
            label: 'Show search',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $showSearchCheck = compSettings.addCheckboxes(showSearchData);
        $fieldset.append($showSearchCheck);

        // search-text
        const searchTextData = {
            data: {
                plugin: 'Slimselect',
                prop: 'dataAttributes',
                attributename: 'search-text'
            },
            name: 'search-text',
            label: 'Search text',
            tooltip: 'The string that will show in the event there are no results.',
            value: this.getAttributeValue('search-text')
        }
        const $searchTextInput = compSettings.addInput(searchTextData);

        $fieldset.append($searchTextInput);

        // search-placeholder
        const searchPlaceholderData = {
            data: {
                plugin: 'Slimselect',
                prop: 'dataAttributes',
                attributename: 'search-placeholder'
            },
            name: 'search-placeholder',
            label: 'Search placeholder',
            tooltip: 'The input search placeholder text.',
            value: this.getAttributeValue('search-placeholder')
        }
        const $searchPlaceholderInput = compSettings.addInput(searchPlaceholderData);

        $fieldset.append($searchPlaceholderInput);

        // close-on-select
        const closeOnSelectData = {
            checkboxes: [{
                checked: this.getAttributeChecked('close-on-select'),
                data: {
                    plugin: 'Slimselect',
                    prop: 'dataAttributes',
                    attributename: 'close-on-select'
                },
                name: 'close-on-select',
                label: '',
                tooltip: 'Determines whether or not to close the content area upon selecting a value.',
                value: true
            }],
            label: 'Close on select',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $closeOnSelectCheck = compSettings.addCheckboxes(closeOnSelectData);
        $fieldset.append($closeOnSelectCheck);

        // select-by-group
        const selectByGroupData = {
            checkboxes: [{
                checked: this.getAttributeChecked('select-by-group'),
                data: {
                    plugin: 'Slimselect',
                    prop: 'dataAttributes',
                    attributename: 'select-by-group'
                },
                name: 'select-by-group',
                label: '',
                tooltip: 'Enables the selection of all options under a specific group by clicking that option group\'s label.',
                value: true
            }],
            label: 'Select by group',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $selectByGroupCheck = compSettings.addCheckboxes(selectByGroupData);
        $fieldset.append($selectByGroupCheck);

        // hide-selected-option
        const hideSelectedOptionData = {
            checkboxes: [{
                checked: this.getAttributeChecked('hide-selected-option'),
                data: {
                    plugin: 'Slimselect',
                    prop: 'dataAttributes',
                    attributename: 'hide-selected-option'
                },
                name: 'hide-selected-option',
                label: '',
                tooltip: 'Hide the current selected option in the dropdown.',
                value: true
            }],
            label: 'Hide selected option',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $hideSelectedOptionCheck = compSettings.addCheckboxes(hideSelectedOptionData);
        $fieldset.append($hideSelectedOptionCheck);

        return $fieldset;
    }
}
