import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Ladda extends Plugins implements Pluginz {
    public objectName: string = 'Ladda';
    public pluginName: string = 'ladda';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'ladda-button',
            value: 'true'
        },
        {
            name: 'style',
            value: 'zoom-in'
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#ladda-example');
        const $legend = $('<legend />').html('Ladda (Button spinner)').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);


        // Style
        const styleData = {
            data: {
                plugin: 'Ladda',
                prop: 'dataAttributes',
                attributename: 'style'
            },
            name: 'style',
            label: 'Style',
            value: this.getAttributeValue('style')
        }
        const styleOptions: Array<string> = ['expand-left', 'expand-right', 'expand-up', 'expand-down', 'contract', 'contract-overlay', 'zoom-in', 'zoom-out', 'slide-left', 'slide-right', 'slide-up', 'slide-down'];
        const $styleSelect = compSettings.addSelect(styleData, styleOptions);

        $fieldset.append($styleSelect);

        return $fieldset;
    }
}
