declare const $: any;

import ComponentSettings from './ComponentSettings';
import { availablePlugins } from '../availablePlugins';
import { defaultConfig } from '../defaultConfig';

export default class FormSettings extends ComponentSettings {
    private userForm: any;

    constructor(section) {
        super(section);
        this.userForm = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // Framework
        const frameworkData = {
            data: {
                prop: 'framework'
            },
            name: 'framework-data',
            label: 'Framework',
            value: this.userForm.framework
        }
        const frameworkOptions: any = [
            {
                'Bootstrap 4': 'bs4'
            },
            {
                'Bootstrap 4 + Material design (Materialize)': 'bs4-material'
            },
            {
                'Bootstrap 5': 'bs5'
            },
            {
                'Bulma': 'bulma'
            },
            {
                'Foundation': 'foundation'
            },
            {
                'Material design': 'material'
            },
            {
                'Tailwind': 'tailwind'
            },
            {
                'UiKit': 'uikit'
            }
        ];
        const $selectFramework = this.addSelect(frameworkData, frameworkOptions);

        $mainTab.push($selectFramework);

        // Layout
        const LayoutData = {
            data: {
                prop: 'layout'
            },
            name: 'layout-data',
            label: 'Layout',
            helper: 'Horizontal = labels beside the fields ; vertical = labels above the fields.<br>The layout is rendered in the preview but not in the drag-and-drop builder.',
            value: this.userForm.layout
        }
        const LayoutOptions: any = [
            {
                'Horizontal': 'horizontal'
            },
            {
                'Vertical': 'vertical'
            }
        ];
        const $selectLayout = this.addSelect(LayoutData, LayoutOptions);

        $mainTab.push($selectLayout);

        // id
        const idData = {
            data: {
                prop: 'id'
            },
            label: 'Form name',
            name: 'form-id',
            pattern: '[a-z0-9_-]+',
            tooltip: 'alphanumeric characters, dashes and underscores only.',
            value: this.userForm.id
        }
        const $inputId = this.addInput(idData);
        $mainTab.push($inputId);

        // Class
        const clazzData = {
            data: {
                prop: 'clazz'
            },
            label: 'Form class',
            name: 'clazz',
            helper: 'Optional additional CSS classes',
            value: this.userForm.clazz
        }
        const $inputClazz = this.addInput(clazzData);
        $mainTab.push($inputClazz);

        // Attr
        const attrData = {
            data: {
                prop: 'attr'
            },
            label: 'Form attributes',
            name: 'form-attr',
            helper: 'Optional additional <a href="https://www.phpformbuilder.pro/documentation/class-doc.php#attr" target="_blank">attributes</a>. Comma separated list without quotes',
            value: this.userForm.attr
        }
        const $inputAttr = this.addInput(attrData);
        $mainTab.push($inputAttr);

        // Icon font
        const selectData = {
            data: {
                prop: 'iconFont'
            },
            name: 'form-icon-font',
            label: 'Icon font',
            value: this.userForm.iconFont
        }
        const selectOptions: any = [];

        Object.keys(defaultConfig.ui.iconLibraries).forEach(key => {
            let value = defaultConfig.ui.iconLibraries[key]['value'];
            let kv = {};
            kv[key] = value;
            selectOptions.push(kv);
        });
        const $selectIconFont = this.addSelect(selectData, selectOptions);

        $mainTab.push($selectIconFont);

        /* Action
        -------------------------------------------------- */

        const $actionTab: Array<JQuery> = [];

        const redirectData = {
            data: {
                prop: 'redirectUrl'
            },
            label: 'Redirect URL',
            name: 'redirect-url',
            helper: 'The URL to where the user will be redirected once everything is done (form sent, validated, email sent, ...). Leave blank to stay on the same page and display the confirmation message.',
            value: this.userForm.redirectUrl
        }
        const $inputRedirect = this.addInput(redirectData);
        $actionTab.push($inputRedirect);

        // afterValidation
        const afterValidationData = {
            data: {
                prop: 'aftervalidation'
            },
            name: 'aftervalidation-data',
            label: 'After validation',
            value: this.userForm.aftervalidation
        }
        const afterValidationOptions: any = [
            {
                'No action': 'no-action'
            },
            {
                'Send email': 'send-email'
            },
            {
                'Database insert': 'db-insert'
            },
            {
                'Database update': 'db-update'
            },
            {
                'Database delete': 'db-delete'
            }
        ];
        const $selectAfterValidation = this.addSelect(afterValidationData, afterValidationOptions);

        $actionTab.push($selectAfterValidation);

        /*=============================================
        =                 Form Actions                =
        =============================================*/

        /* Send mail
        -------------------------------------------------- */

        const $sendMailTab: Array<JQuery> = [];

        const $subTitle1 = $('<h5 />').addClass('px-3 py-2 bg-light fw-light').text('Required data');
        $sendMailTab.push($subTitle1);

        const senderEmailData = {
            data: {
                prop: 'senderEmail'
            },
            label: 'Sender email<sup class="ms-1 text-danger">*</sup>',
            name: 'sender-email',
            helper: 'The email of the sender. Must be a real email address from your domain to avoid SPAM filters',
            value: this.userForm.senderEmail
        }
        const $inputSenderEmail = this.addInput(senderEmailData);
        $sendMailTab.push($inputSenderEmail);

        const recipientEmailData = {
            data: {
                prop: 'recipientEmail'
            },
            label: 'Recipient email<sup class="ms-1 text-danger">*</sup>',
            name: 'recipient-email',
            helper: 'The email destination(s), separated with commas',
            value: this.userForm.recipientEmail
        }
        const $inputRecipientEmail = this.addInput(recipientEmailData);
        $sendMailTab.push($inputRecipientEmail);

        const subjectData = {
            data: {
                prop: 'subject'
            },
            label: 'Subject<sup class="ms-1 text-danger">*</sup>',
            name: 'subject-email',
            helper: 'The email subject',
            value: this.userForm.subjectEmail
        }
        const $inputSubject = this.addInput(subjectData);
        $sendMailTab.push($inputSubject);

        const $subTitle2 = $('<h5 />').addClass('mt-4 px-3 py-2 bg-light fw-light').text('Optional data');
        $sendMailTab.push($subTitle2);

        const senderNameData = {
            data: {
                prop: 'senderName'
            },
            label: 'Sender name',
            name: 'sender-name',
            helper: 'The name of the sender. (optional)',
            value: this.userForm.senderName
        }
        const $inputSenderName = this.addInput(senderNameData);
        $sendMailTab.push($inputSenderName);

        const replyToData = {
            data: {
                prop: 'replyToEmail'
            },
            label: 'Reply to email',
            name: 'reply-to-email',
            helper: 'The email for reply. (optional)',
            value: this.userForm.replyToEmail
        }
        const $inputReplyTo = this.addInput(replyToData);
        $sendMailTab.push($inputReplyTo);

        const sentMessageData = {
            data: {
                prop: 'sentMessage'
            },
            label: 'Sent message',
            name: 'sent-message',
            helper: 'The success message to display on the page (optional)',
            value: this.userForm.sentMessageEmail
        }
        const $inputSent = this.addInput(sentMessageData);
        $sendMailTab.push($inputSent);

        /* Database insert
        -------------------------------------------------- */

        const $dbInsertTab: Array<JQuery> = [];

        const dbInsertData = {
            data: {
                prop: 'dbTable'
            },
            label: 'Table name',
            name: 'db-insert-table',
            value: this.userForm.dbTable
        }
        const $inputDbInsert = this.addInput(dbInsertData);
        $dbInsertTab.push($inputDbInsert);

        const dbInsertPrimaryData = {
            data: {
                prop: 'dbPrimary'
            },
            label: 'Primary key field name',
            name: 'db-insert-primary',
            helper: 'Auto incremented primary key field name',
            value: this.userForm.dbPrimary
        }
        const $inputDbInsertPrimary = this.addInput(dbInsertPrimaryData);
        $dbInsertTab.push($inputDbInsertPrimary);

        /* Database update
        -------------------------------------------------- */

        const $dbUpdateTab: Array<JQuery> = [];

        const dbUpdateData = {
            data: {
                prop: 'dbTable'
            },
            label: 'Table name',
            name: 'db-update-table',
            value: this.userForm.dbTable
        }
        const $inputDbUpdate = this.addInput(dbUpdateData);
        $dbUpdateTab.push($inputDbUpdate);

        const dbUpdateFilterData = {
            data: {
                prop: 'dbFilter'
            },
            label: 'Filter field name',
            name: 'db-update-filter',
            helper: 'Name of the field used to filter the query. ie:<br><code>update `table` SET ... WHERE `field` = $_POST[\'field\'];</code>',
            value: this.userForm.dbFilter
        }
        const $inputDbUpdateFilter = this.addInput(dbUpdateFilterData);
        $dbUpdateTab.push($inputDbUpdateFilter);

        /* Database delete
        -------------------------------------------------- */

        const $dbDeleteTab: Array<JQuery> = [];

        const dbDeleteData = {
            data: {
                prop: 'dbTable'
            },
            label: 'Table name',
            name: 'db-delete-table',
            value: this.userForm.dbTable
        }
        const $inputDbDelete = this.addInput(dbDeleteData);
        $dbDeleteTab.push($inputDbDelete);

        const dbDeleteFilterData = {
            data: {
                prop: 'dbFilter'
            },
            label: 'Filter field name',
            name: 'db-delete-filter',
            helper: 'Name of the field used to filter the query. ie:<br><code>DELETE FROM `table` WHERE `field` = $_POST[\'field\'];</code>',
            value: this.userForm.dbFilter
        }
        const $inputDbDeleteFilter = this.addInput(dbDeleteFilterData);
        $dbDeleteTab.push($inputDbDeleteFilter);

        /* Plugins
        -------------------------------------------------- */

        const $pluginsTab: Array<JQuery> = [];

        // Plugins
        const pluginsOptions: Array<string> = [];
        availablePlugins.form.forEach(Pl => {
            pluginsOptions.push(Pl.name);
        });
        const activePluginsNames: Array<string> = [];
        this.userForm.plugins.forEach(pl => {
            activePluginsNames.push(pl.objectName);
        });
        const pluginsData = {
            data: {
                prop: 'plugins'
            },
            label: 'Plugins',
            name: 'form-plugins[]',
            value: activePluginsNames
        }
        const $selectPlugins = this.addSelect(pluginsData, pluginsOptions);
        $pluginsTab.push($selectPlugins);

        this.userForm.plugins.forEach(pl => {
            const $pluginFieldset = this.loadPlugin(pl, this);
            $pluginsTab.push($pluginFieldset);
        });

        /* Ajax fieldset
        -------------------------------------------------- */

        const $ajaxTab: Array<JQuery> = [];

        // Framework
        const switchData = {
            checkboxes: [{
                checked: this.userForm.ajax === 'true',
                data: {
                    prop: 'ajax'
                },
                name: 'enable-ajax',
                label: '',
                value: 'true'
            }],
            label: 'Enable Ajax loading',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $enableAjax = this.addCheckboxes(switchData);

        $ajaxTab.push($enableAjax);

        /* Load all the tabs
        -------------------------------------------------- */

        $('#main-settings-modal section[id^="user-form-settings-"]').html('');

        $mainTab.forEach($tab => {
            $('#user-form-settings-main').append($tab);
        });

        $actionTab.forEach($tab => {
            $('#user-form-settings-action').append($tab);
        });

        $sendMailTab.forEach($tab => {
            $('#user-form-settings-sendmail').append($tab);
        });

        $dbInsertTab.forEach($tab => {
            $('#user-form-settings-db-insert').append($tab);
        });

        $dbUpdateTab.forEach($tab => {
            $('#user-form-settings-db-update').append($tab);
        });

        $dbDeleteTab.forEach($tab => {
            $('#user-form-settings-db-delete').append($tab);
        });

        $pluginsTab.forEach($tab => {
            $('#user-form-settings-plugins').append($tab);
        });

        $ajaxTab.forEach($tab => {
            $('#user-form-settings-ajax').append($tab);
        });

        // show the 'aftervalidation' collapsible options
        if (this.userForm.aftervalidation !== '') {
            if ($('#' + this.userForm.aftervalidation)[0]) {
                $('#' + this.userForm.aftervalidation).collapse('show');
            }
        }

        this.refreshComponentSettingsFormPlugins($('#main-settings-modal'));
    }
}
