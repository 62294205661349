import Components from '../Interfaces/Components';
import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';

import ComponentsMain from './ComponentsMain';

export default class Textarea extends ComponentsMain implements Components {
    public attr         : Array<Attribute>;
    public clazz        : string;
    public componentType: string = 'textarea';
    public helper       : string = '';
    public index        : number;
    public label        : string;
    public name         : string;
    public placeholder  : string;
    public plugins      : Array<Pluginz> = [];
    public value        : string;
    public width        : string;

    constructor(index, name = 'textarea', value = '', label = 'Textarea', attr = [], clazz = '', placeholder = '', width = '100%') {
        super();
        this.attr        = attr;
        this.clazz       = clazz;
        this.index       = index;
        this.label       = `${label} ${index}`;
        this.name        = `${name}-${index}`;
        this.placeholder = placeholder;
        this.value       = value;
        this.width       = width;

        // merge clazz & placeholder with attributes for PHP Form Builder
        this.attr = this.mergeAttributes(this);
    }
}
