export const defaultConfig = {
    defaultClazz: {
        input: 'form-control',
        select: 'form-control',
        textarea: 'form-control'
    },
    ui: {
        droppableTemplate: "<span id=\"droppable-hover-template\" class=\"d-flex w-100 justify-content-center text-secondary py-1 my-2\">DROP HERE</span>",
        form: {
            "attr": "novalidate",
            "id": "fg-form",
            "layout": "horizontal"
        },
        iconLibraries: {
            "None": {
                "value": "",
                "url": ""
            },
            "Font Awesome (all)": {
                "value": "font-awesome",
                "url": "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
            },
            "Font Awesome Solid": {
                "value": "font-awesome-solid",
                "url": "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
            },
            "Font Awesome Regular": {
                "value": "font-awesome-regular",
                "url": "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
            },
            "Font Awesome Brands": {
                "value": "font-awesome-brands",
                "url": "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
            },
            "Material Icons": {
                "value": "material-icons-filled",
                "url": "https://fonts.googleapis.com/css2?family=Material+Icons"
            },
            "Material Icons Outlined": {
                "value": "material-icons-outlined",
                "url": "https://fonts.googleapis.com/css2?family=Material+Icons+Outlined"
            },
            "Material Icons Round": {
                "value": "material-icons-round",
                "url": "https://fonts.googleapis.com/css2?family=Material+Icons+Round"
            },
            "Material Icons Sharp": {
                "value": "material-icons-sharp",
                "url": "https://fonts.googleapis.com/css2?family=Material+Icons+Sharp"
            },
            "Material Icons Two-tone": {
                "value": "material-icons-two-tone",
                "url": "https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone"
            },
            "Bootstrap Icons": {
                "value": "bootstrap-icons",
                "url": "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"
            },
            "Elegant Icons": {
                "value": "elegant-icons",
                "url": "elegant-icons.min.css"
            },
            "Feather Icons": {
                "value": "feather-icons",
                "url": "feather-icons.min.css"
            },
            "Foundation Icons": {
                "value": "foundation-icons",
                "url": "foundation-icons.min.css"
            },
            "Happy Icons": {
                "value": "happy-icons",
                "url": "happy-icons.min.css"
            },
            "Open-iconic": {
                "value": "open-iconic",
                "url": "open-iconic.min.css"
            },
            "Tabler Icons": {
                "value": "tabler-icons",
                "url": "tabler-icons.min.css"
            },
            "Weather Icons": {
                "value": "weather-icons",
                "url": "weather-icons.min.css"
            },
            "Zondicons": {
                "value": "zondicons",
                "url": "zondicons.min.css"
            }
        },
        sectionClass: "section-component d-flex",
        sectionActiveClass: "active border-yellow-500 bg-yellow-100"
    },
    userForm: {
        "ajax": "false",
        "attr": "novalidate",
        "framework": "bs5",
        "iconFont": "font-awesome",
        "iconFontUrl": "//cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css",
        "id": "fg-form",
        "layout": "horizontal",
        "plugins": [],
        "aftervalidation": "",
        "redirectUrl": "",
        "senderEmail": "",
        "recipientEmail": "",
        "subject": "",
        "senderName": "",
        "replyToEmail": "",
        "sentMessage": "",
        "dbTable": "",
        "dbPrimary": "",
        "dbFilter": ""
    }
};
