declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';
import { availablePlugins } from '../availablePlugins';

export default class Textarea extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // Name
        const nameData = {
            data: {
                prop: 'name'
            },
            label: 'Name',
            name: 'name',
            value: this.component.name,
            pattern: '[a-z0-9_\\[\\]-]+',
            tooltip: 'alphanumeric characters, hyphens, underscores and hooks only.'
        }
        const inputName = this.addInput(nameData);
        $mainTab.push(inputName);

        // Value
        const valueData = {
            data: {
                prop: 'value'
            },
            label: 'Default value',
            name: 'value',
            value: this.component.value
        }
        const inputValue = this.addInput(valueData);
        $mainTab.push(inputValue);

        // Label
        const labelData = {
            data: {
                prop: 'label'
            },
            label: 'Label',
            name: 'label',
            value: this.component.label
        }
        const inputLabel = this.addInput(labelData);
        $mainTab.push(inputLabel);

        // Placeholder
        const placeholderData = {
            data: {
                prop: 'placeholder'
            },
            label: 'Placeholder',
            name: 'placeholder',
            value: this.component.placeholder
        }
        const inputPlaceholder = this.addInput(placeholderData);
        $mainTab.push(inputPlaceholder);

        // HelpText
        const helperData = {
            data: {
                prop: 'helper'
            },
            label: 'Helper',
            name: 'helper',
            tooltip: 'Helper text displayed below the field.',
            value: this.component.helper
        }
        const $inputHelper = this.addInput(helperData);
        $mainTab.push($inputHelper);

        // Class
        const clazzData = {
            data: {
                prop: 'clazz'
            },
            label: 'Class',
            name: 'clazz',
            value: this.component.clazz
        }
        const inputClazz = this.addInput(clazzData);
        $mainTab.push(inputClazz);

        // width
        const isFormHorizontal = $('#fg-form-wrapper form').hasClass('form-horizontal');
        const selectWidthData = {
            data: {
                prop: 'width'
            },
            disabled: !isFormHorizontal,
            name: 'input-width-data',
            tooltip: 'The width can be set only in horizontal forms. It has no effect on others layouts.',
            label: 'Width',
            value: '100%'
        }
        const selectWidthOptions: Array<string> = ['33%', '50%', '66%', '100%'];
        const $selectWidth = this.addSelect(selectWidthData, selectWidthOptions);

        $mainTab.push($selectWidth);

        // Attr
        const checkData = {
            checkboxes: [{
                checked: this.component.attr.find(a => a.name === 'disabled') !== undefined,
                data: {
                    prop: 'disabled'
                },
                label: 'Disabled',
                name: 'disabled',
                value: 'disabled'
            }, {
                checked: this.component.attr.find(a => a.name === 'required') !== undefined,
                data: {
                    prop: 'required'
                },
                label: 'Required',
                name: 'required',
                value: 'required'
            }],
            label: '',
            plugin: 'niceCheck'
        }
        const checkMisc = this.addCheckboxes(checkData);
        $mainTab.push(checkMisc);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        /* Plugins
        -------------------------------------------------- */

        const $pluginsTab: Array<JQuery> = [];

        // Plugins
        const pluginsOptions: Array<string> = [];
        availablePlugins.textarea.forEach(Pl => {
            pluginsOptions.push(Pl.name);
        });
        const activePluginsNames: Array<string> = [];
        this.component.plugins.forEach(pl => {
            activePluginsNames.push(pl.name);
        });
        const pluginsData = {
            data: {
                prop: 'plugins'
            },
            label: 'Select plugins',
            name: 'plugins[]',
            value: activePluginsNames
        }
        const $selectPlugins = this.addSelect(pluginsData, pluginsOptions);
        $pluginsTab.push($selectPlugins);

        this.component.plugins.forEach(pl => {
            const $pluginFieldset = this.loadPlugin(pl, this);
            $pluginsTab.push($pluginFieldset);
        });

        this.tabs.push({
            title: 'Plugins',
            id: 'tab-plugins',
            content: $pluginsTab
        });

        this.renderSection(this.tabs);
    }
}
