import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Autocomplete extends Plugins implements Pluginz {
    public objectName: string = 'Autocomplete';
    public pluginName: string = 'autocomplete';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = {
        src: `["value 1",
"value 2",
"add other values ..."]`};
    public dataAttributes: Array<Attribute> = [];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#autocomplete-example');
        const $legend = $('<legend />').html('Autocomplete').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // Complete list
        const completeData = {
            data: {
                plugin: 'Autocomplete',
                prop: 'replacements',
                replacementkey: 'src'
            },
            label: 'Values',
            name: 'autocomplete-data',
            tooltip: 'Table of quoted values<br>ie: [&quot;value 1&quot;, &quot;value 2&quot;]',
            value: this.replacements.src
        }
        const $textarea = compSettings.addTextarea(completeData);

        $fieldset.append($textarea);

        // Allow multiple choices
        const switchData = {
            checkboxes: [{
                checked: '',
                data: {
                    plugin: 'Autocomplete',
                    prop: 'dataAttributes',
                    attributename: 'multiple-choices'
                },
                name: 'multiple-choices',
                label: '',
                tooltip: 'Whether or not to allow multiple choices',
                value: true
            }],
            label: 'Allow multiple choices',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkMultipleChoices = compSettings.addCheckboxes(switchData);
        $fieldset.append($checkMultipleChoices);

        return $fieldset;
    }
}
