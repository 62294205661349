import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Tinymce extends Plugins implements Pluginz {
    public objectName: string = 'Tinymce';
    public pluginName: string = 'tinymce';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#tinymce-example');
        const $legend = $('<legend />').html('Tinymce').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);


        // jsConfig
        const selectData = {
            data: {
                plugin: 'Tinymce',
                prop: 'jsConfig'
            },
            name: 'tinymce-data',
            label: 'Config.',
            value: this.jsConfig
        }
        const selectOptions: any = [
            {
                'Default': 'default'
            },
            {
                'Light': 'light'
            }
        ];
        const $selectjsConfig = compSettings.addSelect(selectData, selectOptions);

        $fieldset.append($selectjsConfig);

        return $fieldset;
    }
}
