declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';

export default class Hcaptcha extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        const $heading = $('<p />').addClass('alert alert-info mt-4').html('Get your private and public keys on the <a href="https://www.hcaptcha.com/" target="_blank">Hcaptcha website</a>.');
        $mainTab.push($heading);

        // sitekey
        const sitekeyData = {
            data: {
                prop: 'sitekey'
            },
            label: 'hCaptcha site key',
            name: 'hcaptcha-sitekey',
            value: this.component.sitekey
        }
        const $inputSitekey = this.addInput(sitekeyData);
        $mainTab.push($inputSitekey);

        // secretkey
        const secretkeyData = {
            data: {
                prop: 'secretkey'
            },
            label: 'Secret key',
            name: 'hcaptcha-secretkey',
            value: this.component.publickey
        }
        const $inputSecretkey = this.addInput(secretkeyData);
        $mainTab.push($inputSecretkey);

        // Center
        const selectCenterData = {
            data: {
                prop: 'center'
            },
            name: 'center',
            label: 'Center',
            value: this.component.center
        }
        const selectCenterOptions: any = [
            {
                'Yes': 'true'
            },
            {
                'No': 'false'
            }
        ];
        const $centerData = this.addSelect(selectCenterData, selectCenterOptions);

        $mainTab.push($centerData);

        // size
        const selectSizeData = {
            data: {
                prop: 'size',
            },
            name: 'hcaptcha-size',
            label: 'Size',
            value: this.component.size
        }
        const selectSizeOptions: Array<string> = ['compact', 'normal'];

        const $selectSize = this.addSelect(selectSizeData, selectSizeOptions);

        $mainTab.push($selectSize);

        // theme
        const selectThemeData = {
            data: {
                prop: 'theme',
            },
            name: 'hcaptcha-theme',
            label: 'Theme',
            value: this.component.theme
        }
        const selectThemeOptions: Array<string> = ['light', 'dark'];

        const $selectTheme = this.addSelect(selectThemeData, selectThemeOptions);

        $mainTab.push($selectTheme);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        this.renderSection(this.tabs);
    }
}
