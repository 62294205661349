declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';
import { availablePlugins } from '../availablePlugins';

export default class Button extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // Type
        const typeOptions: Array<string> = ['submit', 'reset', 'button'];
        const typeData = {
            name: 'type',
            label: 'Type',
            data: {
                prop: 'type',
            },
            value: this.component.type
        }
        const $selectType = this.addSelect(typeData, typeOptions);
        $mainTab.push($selectType);

        // Name
        const nameData = {
            data: {
                prop: 'name'
            },
            label: 'Name',
            name: 'name',
            value: this.component.name,
            pattern: '[a-z0-9_-]+',
            tooltip: 'alphanumeric characters, hyphens and underscores only.'
        }
        const $inputName = this.addInput(nameData);
        $mainTab.push($inputName);

        // Value
        const valueData = {
            data: {
                prop: 'value'
            },
            label: 'Value',
            name: 'value',
            value: this.component.value
        }
        const $inputValue = this.addInput(valueData);
        $mainTab.push($inputValue);

        // Text
        const labelData = {
            data: {
                prop: 'label'
            },
            label: 'Text',
            helper: 'HTML authorized',
            name: 'label',
            value: this.component.label
        }
        const $inputLabel = this.addInput(labelData);
        $mainTab.push($inputLabel);

        // Center
        const selectCenterData = {
            data: {
                prop: 'center'
            },
            name: 'center',
            label: 'Center',
            value: this.component.center
        }
        const selectCenterOptions: any = [
            {
                'Yes': 'true'
            },
            {
                'No': 'false'
            }
        ];
        const $centerData = this.addSelect(selectCenterData, selectCenterOptions);

        $mainTab.push($centerData);

        // Class
        const clazzData = {
            data: {
                prop: 'clazz'
            },
            label: 'Class',
            name: 'clazz',
            value: this.component.clazz
        }
        const $inputClazz = this.addInput(clazzData);
        $mainTab.push($inputClazz);

        // icon
        const iconData = {
            data: {
                prop: 'icon',
                uniqid: this.makeUniqId(5),
                iconset: $('body').attr('data-icon-font'),
                icon: this.component.icon
            },
            label: 'icon',
            name: 'icon'
        }
        const $inputIcon = this.addIconPicker(iconData);
        $mainTab.push($inputIcon);

        // icon position
        const iconPositionData = {
            data: {
                prop: 'iconPosition'
            },
            name: 'icon-position',
            label: 'Icon position',
            value: this.component.iconPosition
        }
        const iconPositionOptions: any = [
            {
                'Before the text': 'before'
            },
            {
                'After the text': 'after'
            }
        ];
        const $selectIconPosition = this.addSelect(iconPositionData, iconPositionOptions);

        $mainTab.push($selectIconPosition);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        /* Plugins
        -------------------------------------------------- */

        const $pluginsTab: Array<JQuery> = [];

        // Plugins
        const pluginsOptions: Array<string> = [];
        availablePlugins.button.forEach(Pl => {
            pluginsOptions.push(Pl.name);
        });
        const activePluginsNames: Array<string> = [];
        this.component.plugins.forEach(pl => {
            activePluginsNames.push(pl.objectName);
        });
        const pluginsData = {
            data: {
                prop: 'plugins'
            },
            label: 'Select plugins',
            name: 'plugins[]',
            value: activePluginsNames
        }
        const $selectPlugins = this.addSelect(pluginsData, pluginsOptions);
        $pluginsTab.push($selectPlugins);

        this.component.plugins.forEach(pl => {
            const $pluginFieldset = this.loadPlugin(pl, this);
            $pluginsTab.push($pluginFieldset);
        });

        this.tabs.push({
            title: 'Plugins',
            id: 'tab-plugins',
            content: $pluginsTab
        });

        this.renderSection(this.tabs);
    }
}
