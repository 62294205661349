import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class BootstrapSelect extends Plugins implements Pluginz {
    public objectName: string = 'BootstrapSelect';
    public pluginName: string = 'bootstrap-select';
    public isjQuery: boolean = true;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'actions-box',
            value: 'false'
        },
        {
            name: 'live-search',
            value: 'true'
        },
        {
            name: 'show-tick',
            value: 'true'
        },
        {
            name: 'style',
            value: ''
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        let checked;
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#bootstrap-select-example');
        const $legend = $('<legend />').html('Bootstrap Select').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // Actions box
        checked = this.getAttributeChecked('actions-box');
        const actionsBoxData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'BootstrapSelect',
                    prop: 'dataAttributes',
                    attributename: 'actions-box'
                },
                name: 'actions-box',
                label: '',
                value: true
            }],
            label: 'Actions box',
            tooltip: 'On select multiple:<br>When enabled it adds two buttons to the top of the dropdown menu (Select All & Deselect All).',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $actionsBoxCheck = compSettings.addCheckboxes(actionsBoxData);
        $fieldset.append($actionsBoxCheck);

        // Live search
        checked = this.getAttributeChecked('live-search');
        const liveSearchData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'BootstrapSelect',
                    prop: 'dataAttributes',
                    attributename: 'live-search'
                },
                name: 'live-search',
                label: '',
                value: true
            }],
            label: 'Live search',
            tooltip: 'When set to true, adds a search box to the top of the selectpicker dropdown.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $liveSearchCheck = compSettings.addCheckboxes(liveSearchData);
        $fieldset.append($liveSearchCheck);

        // Show tick
        checked = this.getAttributeChecked('show-tick');
        const showTickData = {
            checkboxes: [{
                checked: checked,
                data: {
                    plugin: 'BootstrapSelect',
                    prop: 'dataAttributes',
                    attributename: 'show-tick'
                },
                name: 'show-tick',
                label: '',
                value: true
            }],
            label: 'Show tick',
            tooltip: 'Show checkmark on selected option (for items without multiple attribute).',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $showTickCheck = compSettings.addCheckboxes(showTickData);
        $fieldset.append($showTickCheck);

        // Style
        const selectData = {
            data: {
                plugin: 'BootstrapSelect',
                prop: 'dataAttributes',
                attributename: 'style'
            },
            name: 'bootstrap-select-data',
            label: 'Style',
            value: this.getAttributeValue('style')
        }
        const selectOptions: any = [
            {
                'Default': ''
            },
            {
                'Primary': 'btn-primary'
            },
            {
                'Info': 'btn-info'
            },
            {
                'Success': 'btn-success'
            },
            {
                'Warning': 'btn-warning'
            },
            {
                'Danger': 'btn-danger'
            }
        ];
        const $selectStyle = compSettings.addSelect(selectData, selectOptions);

        $fieldset.append($selectStyle);

        return $fieldset;
    }
}
