import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Formvalidation extends Plugins implements Pluginz {
    public objectName: string = 'Formvalidation';
    public pluginName: string = 'formvalidation';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = {
        language: 'en_US'
    };
    public dataAttributes: Array<Attribute> = [];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/class-doc.php#jquery-validation-getting-started');
        const $legend = $('<legend />').html('Form Validation').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);

        // jsConfig
        const selectData = {
            data: {
                plugin: 'Formvalidation',
                prop: 'replacements',
                replacementkey: 'language'
            },
            name: 'formvalidation-language',
            label: 'Language',
            value: this.replacements.language
        }
        const selectOptions: Array<string> = ['ar_MA', 'bg_BG', 'ca_ES', 'cs_CZ', 'da_DK', 'de_DE', 'el_GR', 'en_US', 'es_CL', 'es_ES', 'eu_ES', 'fa_IR', 'fi_FI', 'fr_BE', 'fr_FR', 'he_IL', 'hi_IN', 'hu_HU', 'id_ID', 'it_IT', 'ja_JP', 'nl_BE', 'nl_NL', 'no_NO', 'pl_PL', 'pt_BR', 'pt_PT', 'ro_RO', 'ru_RU', 'sk_SK', 'sq_AL', 'sr_RS', 'sv_SE', 'th_TH', 'tr_TR', 'ua_UA', 'vi_VN', 'zh_CN', 'zh_TW'];
        const $selectjsConfig = compSettings.addSelect(selectData, selectOptions);

        $fieldset.append($selectjsConfig);

        return $fieldset;
    }
}
