import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import Plugins from './Plugins';

export default class Litepicker extends Plugins implements Pluginz {
    public objectName: string = 'Litepicker';
    public pluginName: string = 'litepicker';
    public isjQuery: boolean = false;
    public selector: string;
    public jsConfig: string = 'default';
    public replacements: any = [];
    public dataAttributes: Array<Attribute> = [
        {
            name: 'litepick',
            value: 'true'
        },
        {
            name: 'auto-apply',
            value: 'true'
        },
        {
            name: 'delimiter',
            value: '-'
        },
        {
            name: 'dropdown-min-year',
            value: '1990'
        },
        {
            name: 'dropdown-max-year',
            value: ''
        },
        {
            name: 'dropdown-months',
            value: 'false'
        },
        {
            name: 'dropdown-years',
            value: 'false'
        },
        {
            name: 'first-day',
            value: '1'
        },
        {
            name: 'format',
            value: 'YYYY-MM-DD'
        },
        {
            name: 'inline-mode',
            value: 'false'
        },
        {
            name: 'lang',
            value: 'en-US'
        },
        {
            name: 'max-date',
            value: ''
        },
        {
            name: 'max-days',
            value: ''
        },
        {
            name: 'min-date',
            value: ''
        },
        {
            name: 'min-days',
            value: ''
        },
        {
            name: 'number-of-columns',
            value: '1'
        },
        {
            name: 'number-of-months',
            value: '1'
        },
        {
            name: 'reset-button',
            value: 'false'
        },
        {
            name: 'single-mode',
            value: 'true'
        },
        {
            name: 'split-view',
            value: 'true'
        },
        {
            name: 'start-date',
            value: ''
        }
    ];

    constructor(selector = '') {
        super();
        this.selector = selector;
    }

    render(compSettings) {
        const $fieldset = $('<fieldset />').addClass(compSettings.ui.fieldsetClass + ' ' + this.objectName + '-fieldset' + this.getJsClass(this.isjQuery));
        const $doc = compSettings.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#litepicker-example');
        const $legend = $('<legend />').html('Litepicker').append($doc).addClass(compSettings.ui.legendClass);
        $fieldset.append($legend);


        // auto-apply
        const switchAutoApplyData = {
            checkboxes: [{
                checked: this.getAttributeChecked('auto-apply'),
                data: {
                    plugin: 'Litepicker',
                    prop: 'dataAttributes',
                    attributename: 'auto-apply'
                },
                name: 'auto-apply',
                label: '',
                value: true
            }],
            label: 'Auto-apply',
            tooltip: 'Hide the apply and cancel buttons, and automatically apply a new date range as soon as two dates are clicked.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkAutoApply = compSettings.addCheckboxes(switchAutoApplyData);
        $fieldset.append($checkAutoApply);

        // delimiter
        const delimiterData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'delimiter'
            },
            name: 'delimiter',
            label: 'Delimiter',
            tooltip: 'Delimiter between dates.',
            value: this.getAttributeValue('delimiter')
        }
        const $inputDelimiter = compSettings.addInput(delimiterData);

        $fieldset.append($inputDelimiter);

        // dropdown-min-year
        const dropdownMinYearData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'dropdown-min-year'
            },
            name: 'dropdown-min-year',
            label: 'Dropdown min year',
            tooltip: 'Minimum year in the Years dropdown selector',
            value: this.getAttributeValue('dropdown-min-year')
        }
        const $inputDropdownMinYearData = compSettings.addInput(dropdownMinYearData);

        $fieldset.append($inputDropdownMinYearData);

        // dropdown-max-year
        const dropdownMaxYearData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'dropdown-max-year'
            },
            name: 'dropdown-max-year',
            label: 'Dropdown max year',
            tooltip: 'Maximum year in the Years dropdown selector',
            value: this.getAttributeValue('dropdown-max-year')
        }
        const $inputDropdownMaxYearData = compSettings.addInput(dropdownMaxYearData);

        $fieldset.append($inputDropdownMaxYearData);

        // dropdown-months
        const switchDropdownMonthsData = {
            checkboxes: [{
                checked: this.getAttributeChecked('dropdown-months'),
                data: {
                    plugin: 'Litepicker',
                    prop: 'dataAttributes',
                    attributename: 'dropdown-months'
                },
                name: 'dropdown-months',
                label: '',
                value: true
            }],
            label: 'Dropdown months',
            tooltip: 'Enables the Years dropdown selector',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkDropdownMonths = compSettings.addCheckboxes(switchDropdownMonthsData);
        $fieldset.append($checkDropdownMonths);

        // dropdown-years
        const switchDropdownYearsData = {
            checkboxes: [{
                checked: this.getAttributeChecked('dropdown-years'),
                data: {
                    plugin: 'Litepicker',
                    prop: 'dataAttributes',
                    attributename: 'dropdown-years'
                },
                name: 'dropdown-years',
                label: '',
                value: true
            }],
            label: 'Dropdown years',
            tooltip: 'Enables the Years dropdown selector',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkDropdownYears = compSettings.addCheckboxes(switchDropdownYearsData);
        $fieldset.append($checkDropdownYears);

        // first-day
        const selectFirstDayData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'first-day'
            },
            name: 'first-day-data',
            label: 'First day',
            tooltip: 'Day of start week. (0 - Sunday, 1 - Monday, 2 - Tuesday, etc…)',
            value: this.getAttributeValue('first-day')
        }
        const selectFirstDayOptions: any = [
            {
                'Sunday': '0'
            },
            {
                'Monday': '1'
            },
            {
                'Tuesday': '2'
            },
            {
                'Wednesday': '3'
            },
            {
                'Thursday': '4'
            },
            {
                'Friday': '5'
            },
            {
                'Saturday': '6'
            }
        ];
        const $selectFirstDay = compSettings.addSelect(selectFirstDayData, selectFirstDayOptions);

        $fieldset.append($selectFirstDay);

        // format
        const formatData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'format'
            },
            name: 'format',
            label: 'Format',
            helper: 'Documentation about Litepicker formats <a href="https://www.phpformbuilder.pro/documentation/javascript-plugins.php#litepicker-data-format" target="_blank">here</a>',
            value: this.getAttributeValue('format')
        }
        const $inputFormat = compSettings.addInput(formatData);

        $fieldset.append($inputFormat);

        // inline-mode
        const switchInlineModeData = {
            checkboxes: [{
                checked: this.getAttributeChecked('inline-mode'),
                data: {
                    plugin: 'Litepicker',
                    prop: 'dataAttributes',
                    attributename: 'inline-mode'
                },
                name: 'inline-mode',
                label: '',
                value: true
            }],
            label: 'Inline mode',
            tooltip: 'Show calendar inline.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkInlineMode = compSettings.addCheckboxes(switchInlineModeData);
        $fieldset.append($checkInlineMode);

        // lang
        const langData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'lang'
            },
            name: 'lang',
            label: 'Language',
            tooltip: 'Language. This option affect to day names, month names via Date.prototype.toLocaleString() and also affect to plural rules via Intl.PluralRules.',
            value: this.getAttributeValue('lang')
        }
        const $inputLang = compSettings.addInput(langData);

        $fieldset.append($inputLang);

        // max-date
        const maxDateData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'max-date'
            },
            name: 'max-date',
            label: 'Max. date',
            tooltip: 'The maximum/latest date that can be selected.<br>Date Object or Unix Timestamp (with milliseconds) or ISO String.',
            value: this.getAttributeValue('max-date')
        }
        const $inputMaxDate = compSettings.addInput(maxDateData);

        $fieldset.append($inputMaxDate);

        // max-days
        const maxDaysData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'max-days'
            },
            name: 'max-days',
            label: 'Max. days',
            tooltip: 'The maximum days of the selected range.',
            value: this.getAttributeValue('max-days')
        }
        const $inputMaxDays = compSettings.addInput(maxDaysData);

        $fieldset.append($inputMaxDays);

        // min-date
        const minDateData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'min-date'
            },
            name: 'min-date',
            label: 'Min. date',
            tooltip: 'The minimum/earliest date that can be selected.<br>Date Object or Unix Timestamp (with milliseconds) or ISO String.',
            value: this.getAttributeValue('min-date')
        }
        const $inputMinDate = compSettings.addInput(minDateData);

        $fieldset.append($inputMinDate);

        // min-days
        const minDaysData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'min-days'
            },
            name: 'min-days',
            label: 'Min. days',
            tooltip: 'The minimum days of the selected range.',
            value: this.getAttributeValue('min-days')
        }
        const $inputMinDays = compSettings.addInput(minDaysData);

        $fieldset.append($inputMinDays);

        // number-of-columns
        const selectNumberOfColumnsData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'number-of-columns'
            },
            name: 'number-of-columns-data',
            label: 'Number of columns',
            tooltip: 'Number of columns months.',
            value: this.getAttributeValue('number-of-columns')
        }
        const selectNumberOfColumnsOptions: any = ['1', '2', '3', '4'];
        const $selectNumberOfColumns = compSettings.addSelect(selectNumberOfColumnsData, selectNumberOfColumnsOptions);

        $fieldset.append($selectNumberOfColumns);

        // number-of-months
        const selectNumberOfMonthsData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'number-of-months'
            },
            name: 'number-of-months-data',
            label: 'Number of visible months',
            tooltip: 'Number of visible months.',
            value: this.getAttributeValue('number-of-months')
        }
        const selectNumberOfMonthsOptions: any = ['1', '2', '3', '4'];
        const $selectNumberOfMonths = compSettings.addSelect(selectNumberOfMonthsData, selectNumberOfMonthsOptions);

        $fieldset.append($selectNumberOfMonths);

        // reset-button
        const switchResetButtonData = {
            checkboxes: [{
                checked: this.getAttributeChecked('reset-button'),
                data: {
                    plugin: 'Litepicker',
                    prop: 'dataAttributes',
                    attributename: 'reset-button'
                },
                name: 'reset-button',
                label: '',
                value: true
            }],
            label: 'Reset button',
            tooltip: 'Adds a reset button to clear the current selection.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkResetButton = compSettings.addCheckboxes(switchResetButtonData);
        $fieldset.append($checkResetButton);

        // single-mode
        const switchSingleModeData = {
            checkboxes: [{
                checked: this.getAttributeChecked('single-mode'),
                data: {
                    plugin: 'Litepicker',
                    prop: 'dataAttributes',
                    attributename: 'single-mode'
                },
                name: 'single-mode',
                label: '',
                value: true
            }],
            label: 'Single mode',
            tooltip: 'Choose a single date instead of a date range.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkSingleMode = compSettings.addCheckboxes(switchSingleModeData);
        $fieldset.append($checkSingleMode);

        // split-view
        const switchSplitViewData = {
            checkboxes: [{
                checked: this.getAttributeChecked('split-view'),
                data: {
                    plugin: 'Litepicker',
                    prop: 'dataAttributes',
                    attributename: 'split-view'
                },
                name: 'split-view',
                label: '',
                value: true
            }],
            label: 'Split view',
            tooltip: 'Enable the previous and the next button for each month.',
            plugin: 'lcswitch',
            dataontext: 'Yes',
            dataofftext: 'No'
        }
        const $checkSplitView = compSettings.addCheckboxes(switchSplitViewData);
        $fieldset.append($checkSplitView);

        // start-date
        const startDateData = {
            data: {
                plugin: 'Litepicker',
                prop: 'dataAttributes',
                attributename: 'start-date'
            },
            name: 'start-date',
            label: 'Start date',
            tooltip: 'Preselect date.<br>If option singleMode is disabled then endDate must be set too.<br>Date Object or Unix Timestamp (with milliseconds) or String (must be equal to option format).',
            value: this.getAttributeValue('start-date')
        }
        const $inputStartDate = compSettings.addInput(startDateData);

        $fieldset.append($inputStartDate);

        return $fieldset;
    }
}
