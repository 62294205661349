import Attribute from '../Interfaces/Attribute';

export default class ComponentSettings {
    protected mergeAttributes(comp) {
        const compProperties = ['clazz', 'placeholder'];
        compProperties.forEach(prop => {
            if (comp[prop] !== undefined && comp[prop].length > 0) {
                const itemObjectAttr = comp.attr.find(att => att.name === prop.replace('clazz', 'class'));
                if (itemObjectAttr === undefined) {
                    const newAttr: Attribute = {
                        name: 'class',
                        value: comp[prop]
                    }
                    comp.attr.push(newAttr);
                } else {
                    itemObjectAttr.value = comp[prop];
                }
            }
        });

        return comp.attr;
    }
}
