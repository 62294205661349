import Button from './Button';
import Components from '../Interfaces/Components';
import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';

export default class Buttongroup implements Components {
    public attr: Array<Attribute>;
    public center: string;
    public clazz: string;
    public componentType: string = 'buttongroup';
    public index: number;
    public name: string;
    public plugins: Array<Pluginz> = [];
    public buttons: Array<Button>;

    constructor(index, name = 'btn-group', center = 'false', attr = [], clazz = '') {
        this.attr = attr;
        this.center = center;
        this.clazz = clazz;
        this.index = index;
        this.name = `${name}-${index}`;
        const btn1Attr: Attribute = {
            name: 'class',
            value: 'btn btn-warning'
        }
        const btn2Attr: Attribute = {
            name: 'class',
            value: 'btn btn-primary'
        }
        const button1: Button = new Button(index, 'reset', this.name + '-button', '', 'Reset', [btn1Attr], 'btn btn-warning');
        const button2: Button = new Button(index + 1, 'submit', this.name + '-button', '', 'Submit', [btn2Attr], 'btn btn-primary');
        this.buttons = [button1, button2];
    }
}
